// let ratio = localStorage.getItem('user-info')
//   ? Number(JSON.parse(localStorage.getItem('user-info'))[1].data.ratio)
//   : 100

let ratio = 100
/**
 * 转时间戳
 *
 * @param { Date } date 时间
 */
export const getTimeStamp = (date) => {
  let str = date.replace(/-/g, '/')
  return new Date(str).getTime()
}

/**
 * 电话号码特殊处理
 *
 * @param { String } phone 时间
 */
export const getNewPhone = (phone) => {
  if (phone.length < 11) {
    let len = 10 - phone.length
    let num = phone
    for (let i = 0; i < len; i++) {
      num = `0${num}`
    }
    // return `%2B62${num}`
    // return `62${num}`
    return `91${num}`
  } else {
    // return `%2B62${phone}`
    // return `62${phone}`
    return `91${phone}`
  }
}

export const getSum = (m, n) => {
  return Math.floor(Math.random() * (m - n) + n)
}

/**
 * 倒计时
 */
export const countTime = (endTime = '2020-12-1 15:00:00') => {
  //获取当前时间
  let date = new Date()
  let now = date.getTime()
  // console.log(now)
  //设置截止时间
  let str = endTime
  let endDate = new Date(str)
  let end = endDate.getTime()
  // console.log(end)

  //时间差
  let leftTime = end - now
  //定义变量 d,h,m,s保存倒计时的时间
  let d, h, m, s
  if (leftTime >= 0) {
    d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
    h = Math.floor((leftTime / 1000 / 60 / 60) % 24)
    m = Math.floor((leftTime / 1000 / 60) % 60)
    s = Math.floor((leftTime / 1000) % 60)
  }
  // console.log(d + '天' + h + '时' + m + '分' + s + '秒')
  // console.log(h + '时' + m + '分' + s + '秒')
  //递归每秒调用countTime方法，显示动态时间效果

  setTimeout(countTime, 1000)
  return d + '天' + h + '时' + m + '分' + s + '秒'
}

export const getMyTime = (nS) => {
  var date = new Date(parseInt(nS) * 1000)
  var year = date.getFullYear()
  var mon = date.getMonth() + 1
  var day = date.getDate()
  var hours = date.getHours()
  var minu = date.getMinutes()
  var sec = date.getSeconds()

  return year + '/' + mon + '/' + day + ' ' + hours + ':' + minu + ':' + sec
}

export const getMyTime2 = (nS) => {
  var date = new Date(parseInt(nS) * 1000)
  var year = date.getFullYear()
  var mon = date.getMonth() + 1
  var day = date.getDate()
  var hours =
    date.getHours().toString().length < 2
      ? `0${date.getHours().toString()}`
      : date.getHours().toString()
  var minu =
    date.getMinutes().toString().length < 2
      ? `0${date.getMinutes().toString()}`
      : date.getMinutes().toString()
  var sec =
    date.getSeconds().toString().length < 2
      ? `0${date.getSeconds().toString()}`
      : date.getSeconds().toString()
  return year + '-' + mon + '-' + day + ' ' + hours + ':' + minu + ':' + sec
}

/**
 * 分转元
 * @param { String } money
 *
 */
export const deciTurnMoney = (money) => {
  return ((money * 1) / ratio).toFixed(2)
}

/**
 * 元转分
 * @param { String } money
 *
 */
export const moneyTurnDeci = (money) => {
  return Number((money * ratio).toFixed(0))
}

export const getToken = () => {
  return localStorage.getItem('token')
}

export const getUserInfo = () => {
  let val = cookie.get('userInfo')
  if (val) {
    let value = JSON.parse(cookie.get('userInfo'))
    if (Object.keys(value).length) {
      return value
    } else {
      return { money_not: '', money: '0' }
    }
  } else {
    return { money_not: '', money: '0' }
  }
}

export const cookie = {
  set: (key, data) => {
    try {
      localStorage.setItem(key, data)
    } catch (error) {
      console.error(error)
    }
  },
  get: key => {
    try {
      let value = localStorage.getItem(key)
      return value
    } catch (error) {
      console.error(error)
    }
  },
  remove: key => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      console.error(error)
    }
  },
  clear: () => {
    try {
      localStorage.clear()
    } catch (error) {
      console.error(error)
    }
  }
}

export const formatDate = (format, v) => {
  if (format === '') {
    format = 'Y-m-d H:i:s'
  }
  if (typeof v === 'undefined') {
    v = new Date().getTime()
  } else if (/^(-)?\d{1,10}$/.test(v)) {
    v = v * 1000
  } else if (/^(-)?\d{1,13}$/.test(v)) {
    v = v * 1000
  } else if (/^(-)?\d{1,14}$/.test(v)) {
    v = v * 100
  } else if (/^(-)?\d{1,15}$/.test(v)) {
    v = v * 10
  } else if (/^(-)?\d{1,16}$/.test(v)) {
    v = v * 1
  } else {
    return v
  }
  let dateObj = new Date(v)
  if (parseInt(dateObj.getFullYear()) + '' === 'NaN') {
    return v
  }
  //
  format = format.replace(/Y/g, dateObj.getFullYear())
  format = format.replace(/m/g, zeroFill(dateObj.getMonth() + 1, 2))
  format = format.replace(/d/g, zeroFill(dateObj.getDate(), 2))
  format = format.replace(/H/g, zeroFill(dateObj.getHours(), 2))
  format = format.replace(/i/g, zeroFill(dateObj.getMinutes(), 2))
  format = format.replace(/s/g, zeroFill(dateObj.getSeconds(), 2))
  return format
}

export const zeroFill = (str, length, after) => {
  str += ''
  if (str.length >= length) {
    return str
  }
  let _str = '',
    _ret = ''
  for (let i = 0; i < length; i++) {
    _str += '0'
  }
  if (after || typeof after === 'undefined') {
    _ret = (_str + '' + str).substr(length * -1)
  } else {
    _ret = (str + '' + _str).substr(0, length)
  }
  return _ret
}

export const format = (date, format) => {
  if (date) {
    if (format === void 0) {
      format = 'yyyy-MM-dd HH:mm:ss';
    }
    if (typeof date == 'number' || typeof date == 'string') {
      // date = date.substr(0, 10) + "\n" + date.substr(11, 18);
      if (typeof date == 'number' && date.toString().length == 10) {
        date = date * 1000;
      }
      date = new Date(date);
    }
    var times = {
      y: date.getFullYear(),
      M: date.getMonth() + 1,
      d: date.getDate(),
      H: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds()
    };
    var timeStr = format;
    var RegArr = {
      fullyearReg: new RegExp('yyyy'),
      yearReg: new RegExp('yy'),
      MMReg: new RegExp('MM'),
      hhReg: new RegExp('HH'),
      mmReg: new RegExp('mm'),
      ssReg: new RegExp('ss')
    };
    for (var i = 0; i < Object.keys(times).length; i++) {
      var key = Object.keys(times)[i];
      var target = times[key] >= 10 ? times[key].toString() : "0" + times[key];
      var newStr = timeStr.replace(new RegExp(key + "+"), target);
      timeStr = newStr;
    }
    return timeStr;
  }
}