export default {
  // 公共的key
  common: {
    unrealized: 'Ainda não implementado',
    all: 'todos',
    distributionTip: 'A partir de $0 entrega 0 taxa de entrega',
    easeBack: 'Paz de espírito',
    addCarSuccess: 'Adicionado com sucesso ao carrinho',
    deleteConfirm: 'Tem certeza de que deseja remover os itens selecionados?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: 'Detalhes da mercadoria',
    shopping: 'Rush em venda',
    end: 'Fim da distância',
    sold: 'vendidos',
    goodsTips: 'Este item é avaliado em 500g/ porção. Se o valor recebido for inferior a 500g, a diferença será reembolsada',
    quickTime: 'Entrega em 29 minutos',
    specifications: 'especificação',
    preservationConditions: 'Condições de conservação',
    coldStorage: 'refrigeração',
    shelfLife: 'Prazo de validade',
    day: '15 dias',
    addToCar: 'Adicionar ao carrinho'
  },
  //购物车
  car: {
    title: 'Carrinho de compras',
    delete: 'apagar',
    tip: 'Carrinho vazio drops~',
    walk: 'Ir e passear',
    all: 'Cobrir todos',
    guess: 'Acho que você gosta'
  },
  // 首页
  home: {
    HallofFame:'Hall of Fame',
    chooseLocation: 'Por favor, selecione uma localização..',
    more: 'mais',
    home: 'Ficha técnica',
    category: 'classificação',
    eat: 'O que comer',
    cart: 'Carrinho de compras',
    mine: 'O meu',
    inputGoodsName: 'Digite o nome comercial',
    vipDesc: 'Junte-se ao membro · economia estimada de $806 por ano',
    vipSubDesc: 'Cartão aberto com 50% de desconto>',
    specialZone: 'Áreas especiais em destaque',
    foodie: 'Festival dos foodies',
    dinner: 'O jantar.',
    popularity: 'O sentimento',
    goodChoose: 'O coração escolhe',
    limitBuy: 'Venda flash disponível',
    bottomTip: 'Eu tenho uma linha',
    chooseAddress: 'Por favor selecione um endereço',
    currentLocation: 'Sua localização atual',
    nearbyAddress: 'Endereços nas proximidades'
  },
  // 分类
  category: {
    tip: 'Digite o nome comercial',
    bottomTip: 'Isso é tudo. Veja as outras categorias.'
  },
  // 吃什么
  eat: {
    all: 'todos',
    close: 'guarda',
    pleaseHolder: 'Procurar receitas, ingredientes',
    menu: 'Menu do dia',
    menuDevide: 'Menu por categoria',
    recent: 'Pesquisado recentemente',
    tip_bottom: 'Raios, pára de puxar!',
    tip: 'Coleção ainda não realizada oh~'
  },
  // 我的
  mine: {
    title: 'O meu',
    phone: 'Telefone celular não',
    login: 'Entrar agora mesmo',
    myOrder: 'A minha encomenda',
    allOrder: 'Ver pedido completo',
    myBill: 'Os meus cupons',
    myLocation: 'Meu endereço de entrega',
    myCar: 'Meu green card',
    servier: 'Contacte o atendimento',
    servierTime: 'Horário de atendimento 07:00-22:00',
    feedback: 'Comentários comentários comentários',
    switchLanguage: 'línguas',
    chinese: 'chinês',
    english: 'inglês',
    version: 'A versão atual',
    waitingPay: 'A ser pago',
    waitingReceive: 'Para receber',
    waitingComment: 'Para avaliação',
    waitingFeedback: 'Pós-venda/reembolso',
    unrealized: 'A página de reembolso ainda não está disponível.!',
    tip2: 'Lembra um pouco uma pequena estrela ❤ ️ oh~',
    tip3: '💘 obrigado pela atenção 💘',
    feedbackTip: 'Ainda não há pedidos relevantes',
    cardPrise: 'Preço do green card',
    moreMessage: 'Mais privilégios em breve...',
    openCard: 'Abrir um green card',
    recomend: 'Abrir green card para desfrutar de três interesses, espera-se que cada pedido economize RMB 6.66',
    cardTip1: 'Cupom exclusivo para',
    cardTip2: 'Preços especiais exclusivos',
    cardTip3: '2x integração',
    cardTip4: 'Ainda mais privilégios',
    vipType: '5 vezes',
    vipTime: 'Passe anual ·365 dias',
    vipRecommend: 'Equivalente a cerca de $0.24 / dia',
    payMethod: 'Formas de pagamento',
    wechatPay: 'Pagamento com wechat',
    aliPay: 'Pagamento com alipay',
    huabeiPay: 'Gastar e pagar',
    payImmeatally: 'Pague agora mesmo',
    cardUeless: 'Green card não aberto',
    tip: 'É aqui que temos de chegar! A concubina lá atrás não consegue.~~',
    changeNickName: 'Alterar seu apelido',
    confirm: 'conservação',
    nickName: 'Por favor insira um novo apelido',
    personal: 'Perfil modificado com sucesso!',
    message: 'Por favor insira um apelido',
    myBills: 'Os meus cupons',
    bill: 'cupão',
    condition: 'Até $1.5 fora sem limite de uso \n',
    rmb: 'yuan',
    myOrders: 'A minha encomenda',
    itemsTitle: ['todos', 'A ser pago', 'Para receber', 'Para avaliação'],

    personalInfo: 'Dados pessoais disponíveis',
    head: 'cabeça',
    user_name: 'alcunha',
    userSex: 'sexo',
    Brithday: 'aniversário',
    phoneNumber: 'Telefone celular não',
    noInput: 'Não preenchido',
    logout: 'Sair do login',
    infoTip: 'Perfil modificado com sucesso',
    loginInfo: 'Tem certeza de sair do login?',
    infoSuccess: 'Sair com sucesso',
    year: 'anos',
    month: 'mês',
    day: 'De outubro',

    greenCard: 'residência',
    greenCardPower: 'Privilégios do green card',
    fiftypecert: '50% de desconto na abertura do green card',
    cardRecoamnd: 'Green card exclusivo obter benefícios todos os dias',
    TodayExclusivecoupon: 'Cupom exclusivo do dia',
    everyUpadate: 'Atualizado diariamente 0 pontos',
    to: 'cheia',
    toUse: 'Uso do meta',
    immeatallyGet: 'Receba agora mesmo',
    weekBill: 'Voucher exclusivo da semana',
    cardTip7: 'Receba agora mesmo',
    billTip: 'Green card exclusivo obter benefícios todos os dias',
    currentGoods: 'Suas compras atuais',
    cardShop: 'Abrir green card para compras',
    cardBack: 'Os pontos de reembolso são',
    cardSpecialTip: 'Aceleração integral de ativação imediata',
    cardSpecial: 'Green card especial exclusivo',
  },
  // 订单
  order: {
    outTimeGetMoney: 'Mais de 10 minutos para obter compensação de pontos',
    inputForm: 'Preencha seu pedido',
    location: 'Escolha um endereço de entrega',
    arrivalTime: 'Tempo de entrega',
    total: 'total',
    thing: 'Uma coisa',
    wechatPay: 'Pagamento com wechat',
    aliPay: 'Pagamento com alipay',
    huabeiPay: 'Gastar e pagar',
    use: 'utilização',
    order12: 'Troca de pontos',
    mark: 'observações',
    tip: 'Preenchimento opcional, pode nos dizer suas necessidades especiais',
    goodsList: 'Lista de bens',

    totalMoney: 'Quantidade da mercadoria',

    sendMoney: 'Custo de entrega',
    point: 'integração',
    order19: 'Efectivamente pago',

    pay: 'Efectivamente pago',
    sendForm: 'Envie seu pedido',

    selectArrivalTime: 'Escolha o tempo de entrega',
    comfirm: 'A certeza',
    goods: 'produtos',
    all: 'total',
    things: 'Uma coisa',
    sigalPrice: 'Preço unitário:',
    numbers: 'quantidade:',
    addTip: 'Ainda não adicionou um endereço. Adicione um 😄',

    addLocation: 'Adicionar um endereço',
    comfirmAndUse: 'Salvar e usar',
    editLocation: 'Editar o endereço',
    myAddress: 'O meu endereço',
    settlement: 'liquidação',
    chooseGoods: 'Por favor, selecione a mercadoria para liquidar',
    deliveryTime: 'Por favor, selecione um tempo de entrega',
    today: 'hoje',
    tomorrow: 'amanhã'
  },
  Login: {
    indexTitle: 'inicio',
    Daftar: {
      title: 'De registo',
      phone: 'Por favor insira o seu número de telemóvel',
      code: 'Por favor insira o captcha',
      password: 'Por favor insira o código',
      againPassword: 'Por favor, confirme digitando a senha novamente'
    },
    Forgot: {
      sendCode: 'enviar',
      confirm: 'apresentação',
      goBack: 'regresso'
    },
    nameInput: 'Por favor insira o seu número de telemóvel',
    pwdInput: 'Por favor insira o código',
    remenber: 'Lembrar a senha',
    loginButton: 'autenticação',
    registrasi: 'De registo'
  },
  // 登录
  login: {
    title: 'autenticação',
    phoneNumber: 'Telefone celular não',
    phoneNumberNotCorrect: 'Formato incorreto do número do celular',
    phoneNumberNotEmpty: 'Número de telemóvel não pode estar vazio',
    passNumberNotEmpty: 'A senha não pode estar vazia',
    phoneInput: 'Por favor insira o número do telemóvel',
    pass: 'senha',
    passTip: 'Por favor insira o código',
    varify: 'Por favor insira o captcha',
    otherMethods: 'Outros métodos de login',
    wechat: 'Entrar no wechat',
    qqchant: 'QQ entrar em',
    tipTile: 'Dicas quentes:',
    tipContent: 'Número de telefone celular não registrado, que será registrado automaticamente ao fazer login, e o representante concorda',
    tip: 'Acordo do usuário',
    tipProcy: 'Politica de privacidade',
    login: 'autenticação',
    resgin: 'De registo',
    passTip2: 'Digite uma senha (pelo menos 6 dígitos)',
    sendVerify: 'Enviar código de verificação',
    hasSend: 'Já foi enviado',
    phoneVerify: 'Conta senha login',
    smsMessage: 'SMS código de verificação login',
    switchLoginMsg: 'Conta senha login',
    messageSuccess: 'Código de verificação obtido com sucesso. Por favor, insira-o na caixa de entrada',
    pleaseInputCorrectPhoneNumber: 'Por favor, insira o número de telefone correto',
    pleaseInputCorrectVerifyumber: 'Por favor, insira o captcha correto',
    otherWechat: 'Wechat login- temporariamente incompleto',
    otherQQ: 'QQ login- temporariamente incompleto',
    message: 'Código de verificação:'
  },
  Hint: {
    notMore: 'Não há mais',
    loading: 'Carregando em',
    failed: 'fracasso',
    SendCodeSuccess: 'Código de verificação enviado com sucesso!',
    phoneNot: 'Número de telefone/conta não pode estar vazio',
    pwdInconformity: 'Duas entradas de senha inconsistentes',
    notInput: 'A entrada está incompleta',
  },
  newTab: {
    home: 'Ficha técnica',
    money: 'comprar',
    order: 'ordens',
    my: 'O meu',
  },
  tab: {
    make_money: 'ordens'
  },
  My: {
    download: 'Baixe o APP',
    personal: 'Sua informação pessoal',
    recharge: 'Recarregar o registro',
    withdrawal: 'Vários locais',
    language: 'Configurações de idioma',
    logout: 'Sair.',
    MemberInformation: {
      balance: 'Saldo da conta',
      Withdraw: 'Vários locais',
      Recharge: 'Para recarregar',
      forzenAll: 'Montante total congelado'
    },
    PersonalInformation: {
      Page: {
        Avatar: 'cabeça',
        Nickname: 'alcunha',
        PhoneNumber: 'Número de telefone',
        BankAccounts: 'Cartão bancário',
        Password: 'senha',
        title: 'Sua informação pessoal'
      },
      portrait: {
        SetAvatar: 'Configurações de avatar',
        button: 'alterações'
      },
      Name: {
        ModifyNickname: 'Alterar seu apelido',
        title: 'Alterar seu apelido',
        name: 'alcunha',
        Please: 'Por favor, insira o apelido que deseja alterar'
      },
      Password: {
        title: 'Alterar senha de login',
        PleaseEnter: 'Digite a senha que deseja alterar',
        phone: 'Número de celular',
        NewPhone: 'Por favor insira o seu número de telemóvel',
        verificationCode: 'Código de verificação',
        verificationCodeNote: 'Por favor insira o captcha',
        ConfirmPassword: 'senha',
        ConfirmPasswordNote: 'Digite a senha que deseja alterar',
        Text: 'Lembre-se de sua senha. Se você esqueceu sua senha, entre em contato com o atendimento ao cliente.',
        Button: 'Confirmar a modificação'
      },
      AccountData: {
        title: 'Detalhes da transação'
      }
    }
  },
  newAdd: {
    all: 'todos',
    Completed: 'Já foi concluído',
    unfinished: 'Não está terminado',
    Income: 'receitas',
    Expenditure: 'despesa',
    GetCodeBtn: 'Obter código de verificação',
    copySuccess: 'Replicar com sucesso',
    copyError: 'Falha na cópia',
    noLoginUserName: 'Por favor faça login',
    Withdraw: 'Vários locais',
    Recharge: 'Para recarregar',
    commission: 'comissão',
    single: 'único',
    missionHall: 'Lobby de missões',
    videoTutorial: 'Tutoriais em vídeo',
    helpCenter: 'Centro de ajuda',
    inviteFriends: 'Convide um amigo',
    help1Title: 'Como ganhar comissões com CG',
    help1Context: 'O proprietário do comércio eletrônico precisa adicionar as vendas da loja, então só precisamos preencher os requisitos da loja de e-shop no CG e completar o pedido. O proprietário vai nos dar uma comissão. Cada usuário pode receber até 10 tarefas de ordem por dia. Você pode ganhar uma comissão de 10% a 30% em um dia.',
    help2Title: 'Como aumentar o número de tarefas',
    help2Context: 'Devido ao grande número de funcionários a tempo parcial, a empresa estabelece que todos os funcionários a tempo parcial on-line usam o único whatsapp para entrar em contato e receber tarefas',
    help3Title: 'Como completar as tarefas',
    help3Context: 'Ajude os grandes comerciantes a aumentar suas vendas e recompense você com base no número de transações. Para cada pedido bem-sucedido, você ganha uma comissão e o vendedor ganha uma reputação.',
    referralLink: 'Sugira um link',
    copy: 'reproduzir',
    blance: 'equilíbrio',
    todayOrder: 'Ordem de hoje',
    provided: 'Estes dados são fornecidos pelo',
    Transaction: 'acordo',
    Frozen: 'congelados',
    Done: 'terminar',
    Cancel: 'cancelar',
    ExpectedReturn: 'Retorno esperado para:',
    ImmediatePayment: 'Pague agora mesmo',
    AvailableNum: 'Tempos de disponibilidade',
    OrderImmediately: 'Faça seu pedido agora',
    MakeMoneyEveryDay: 'Ganhe dinheiro dia a dia',
    TodayRecord: 'Recorde do dia',
    AllocatCommissions: 'Distribua suas comissões',
    FrozenAmount: 'Montante congelado',
    TaskMatch: 'Distribuição de tarefas',
    AvailableBalance: 'Saldo disponível disponível',
    WaitDispatch: 'Aguardando atribuição de tarefas, entre em contato com o gerente da loja!',
    OrderDescription: 'Recorde do dia',
    CouldMatch: 'Cada conta pode ser combinada por dia',
    OrderPerDay: 'único。',
    DescriptionRate: 'Comissão de colocação fixa no valor do pedido',
    DescriptionPeriod: '。',
    Description3: 'Sistema baseado na tecnologia LBS para combinar mercadorias automaticamente através da nuvem。',
    Description4: 'Para evitar o monitoramento da plataforma, se a ordem for confirmada e enviada dentro de 20 minutos, o sistema congelará a quantidade da ordem。',
    Note: 'Precauções e precauções:',
    DescriptionNote: 'Quando a plataforma corresponde a um pedido para o usuário, a plataforma envia as informações do pedido para o back office do comerciante. Se o usuário não enviar um pedido dentro de 20 minutos, \
O valor do pedido será bloqueado pelo sistema. Para evitar o sistema de regulação, as contas de usuário serão avaliadas, afetando diretamente o usuário da próxima vez que o \
Tempo e boca a boca! Se houver outros que precisam ser tratados, por favor, clique em parar a ordem a tempo para evitar o congelamento fora do tempo',
    OrderNum: 'Número de encomenda:',
    TotalOrderPrice: 'Preço total:',
    SubmitNow: 'Envie agora mesmo',
    Record: 'registos',
    MemberAccount: 'Conta de membro',
    AccountPlaceholder: 'Por favor, insira sua conta',
    RechargeAmount: 'Montante da recarga',
    RechargeAmountPlaceholder: 'Por favor insira o valor de recarga',
    SelectRechargeMethod: '选择Escolha um método de recarga充值方式',
    NotBeenSentOrder: 'O pedido ainda não foi entregue. Entre em contato com a equipe para distribuir o pedido!',
    TodayOrderOver: 'Pedido concluído hoje, tente novamente amanhã!',
    NotLogin: 'Você não está logado, faça login e tente novamente!',
    HaveNoteMethod: 'Este método de recarga não está disponível no momento. Por favor, escolha outro método de recarga~',
    Login: 'autenticação',
    AccountRecord: 'Registro de mudança de conta',
    RealName: 'Nome verdadeiro nome',
    IFSCCODE: 'IFSC',
    BankName: 'Nome do cartão',
    BankNum: 'Número do cartão bancário',
    Mobile: 'telemóvel',
    MobileNum: 'Número de celular',
    Email: 'Caixa de correio',
    EmailRequired: 'Email é necessário',
    AddBankCardNote: 'O cartão bancário é uma informação importante, preencha-o cuidadosamente!',
    Submit: 'apresentação',
    AddBankCardTitle: 'Adicionar cartão bancário',
    BankCard: 'Cartão bancário',
    Name: 'O nome',
    CardNum: 'Número do cartão',
    Reading: 'Em leitura...',
    AllBalance: 'Saldo total disponível',
    EnterWithdrawalAmount: 'Por favor, insira o valor refletido',
    AllOut: 'todos',
    AskNow: 'Vários locais',
    EnterPwdPlaceholder: 'Por favor insira o código',
    NotHaveCard: 'Você ainda não vinculou um cartão bancário. Você quer vinculá-lo?',
    GetBankInfoFail: 'Falha ao puxar informações bancárias',
    EnterValidPayPwd: 'Por favor, insira uma senha de pagamento válida',
    Balance: 'equilíbrio',
    Total: 'No total',
    total: 'No total',
    records: 'registos',
    DisplayPerPage: 'Mostrar por página',
    NullNum: 'linha',
    pages: 'páginas',
    toAudit: 'Para revisão',
    succeed: 'sucesso',
    RechargeRecordTitle: 'Recarregar o registro',
    rests: 'outros',
    Arrive: 'Na conta',
    forzenCommission: 'Congelar a comissão',
    companyIntro: 'Perfil da empresa',
    companyTitle: 'Tata mall faz parte do grupo tata',
    companyText: 'O grupo tata é o maior grupo empresarial da índia, fundado em 1868 e com sede em mumbai, índia. As operações comerciais abrangem sete áreas de negócio: tecnologias de comunicação e informação, engenharia, materiais, serviços, energia, bens de consumo e produtos químicos. Como um grupo empresarial em rápido crescimento na índia, o grupo tata tem mais de 200 subsidiárias em mais de 80 países em seis continentes e exporta seus produtos e serviços para 85 países. A receita total do grupo tata para o ano fiscal 2020-2021 foi de us $100, 9 bilhões, 58% dos quais provêm de operações no exterior. O grupo emprega cerca de 450.000 pessoas em todo o mundo. Desde 153, o nome tata tem sido amplamente respeitado na índia por defender bons valores e ética nos negócios. 11 ª empresa mais admirada do mundo pela forbes.。',
    depositAddress: 'Dirección de recarga',
    rechargeSteps: 'Pasos para recargar',
    completePayment: 'Recarga completa',
    rechargeNow: 'Recarga ahora mismo',
    rechargeCancel: 'Cancelar una recarga',
    bankName: 'Nombre del banco',
    payee: 'receptor',
    cardNum: 'Cuentas de cobro',
    reviewing: 'Em revisão',
    remittance: 'enviado',
    notThrough: 'Não passou',
    OrderID: 'ordens ID'
  }
}