import Axios from "axios";
import Cookies from "js-cookie";
import { stringify } from "qs";
import { Toast } from "vant";
import { router } from "../router";
import { getToken } from "./tools";
import verConfig from "./verConfig";
import { getCookieValue } from "./common";

/* Network Error Message */
const ERROR_MESSAGE = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  // REQUEST_ERROR: '发送请求时出现错误',
  REQUEST_ERROR: 'An error occurred while sending the request',
  // NO_RESPONDING: '服务器无响应',
  NO_RESPONDING: 'The server is not responding.',
  // UNKNOWN_ERROR: '未知错误',
  UNKNOWN_ERROR: 'unknown error',
}

function getLanguage(){
  const chooseLanguage = Cookies.get("language");
  if (!chooseLanguage) {
    Cookies.set("language", verConfig.lan || "en");
    localStorage.setItem('language', verConfig.lan || "en")
  }
  return chooseLanguage || verConfig.lan || "en"
}
const urlencodedReg = new RegExp("application/x-www-form-urlencoded+");
const jsonReg = new RegExp("application/json+");
// set loading
let reqNum = 0;
function setLoading() {
  if (!reqNum) {
    Toast.loading({ duration: 0, forbidClick: true })
  }
  reqNum++;
}
function clearLoading() {
  reqNum--;
  if (reqNum > 0) return
  Toast.clear();
}
const commomHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Requested-With': 'XMLHttpRequest',
}

const _axios = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? "/api" : `${verConfig.url}/api`,
  headers: commomHeaders
})

_axios.interceptors.request.use(config => {
  if (urlencodedReg.test(config.headers["Content-Type"])) {
    config.data = stringify(config.data || {});
  }
  if (jsonReg.test(config.headers["Content-Type"])) {
    config.data = JSON.stringify(config.data || {});
  }
  config.headers.token = getToken();
  config.headers.lang = getLanguage();
  config.headers["platform-channel"] = getCookieValue('channel');
  return config
}, err => {
  console.error("axios的request拦截器报错:", err);
  return Promise.reject(ERROR_MESSAGE.REQUEST_ERROR)
})

_axios.interceptors.response.use(({ status, data: { ret, msg, data }, config: { headers } }) => {
  if (headers.showToast) {
    clearLoading();
  }
  if (status === 200) {
    if (ret == -1) {
      if(verConfig.notLoginToRegist){
        return router.replace("/registrasi");
      }
      return router.replace("/login")
    }
    if (ret == 1) {
      return { ret, msg, data }
    }
    return Promise.reject({ ret, msg, data })
  }
  return Promise.reject(err)
}, err => {
  console.error("axios的response拦截器报错:", err);
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const status = err.response.status
    // eslint-disable-next-line no-prototype-builtins
    if (ERROR_MESSAGE.hasOwnProperty(status)) {
      Toast(`${status} ${ERROR_MESSAGE[status]}`)
    } else {
      Toast(ERROR_MESSAGE.UNKNOWN_ERROR)
    }
  } else if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    Toast(ERROR_MESSAGE.NO_RESPONDING)
  }
  return Promise.reject(err)
})

function request(url, options = {}) {
  let showToast = true;
  if ("showToast" in options) {
    showToast = options.showToast;
  }
  const headers = options.headers || {}
  if (showToast) {
    headers.showToast = true;
    setLoading();
  }
  const method = !!options.method ? options.method.toUpperCase() : "GET";
  const params = stringify(options.params || {})
  let data = options.data || {};
  url += !!params ? `?${params}` : "";
  return _axios({ url, method, data, headers })
}

export const $get = (url, data, option) => request(url, { params: data, ...option })
export const $post = (url, data, option) => request(url, { method: "POST", data, ...option })
export const $upload = (data, option) => {
  const url = option.url || "/users/upload";
  let params = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const value = data[key];
      params.append(key, value)
    }
  }
  return $post(url, data, {
    headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
    ...option
  })
}

// 错误处理
export function dealError(error) {
  if (typeof error === "string") {
    Toast(error)
  } else if (!!error.msg) {
    Toast(error.msg)
  } else {
    console.error("获取数据错误:", error)
  }
}

export default request