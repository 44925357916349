const user = {
  state: {
    active: 0
  },
  mutations: {
    SET_ACTIVE: (state, active) => {
      state.active = active
    }
  },
  actions: {
    setActive({ commit }, active) {
      commit('SET_ACTIVE', active)
    }
  },
}
export default user
