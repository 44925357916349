import { $post, $get, dealError } from "@/utils"
import { router } from "../router"
import { setToken } from "../utils/tools"
import dataDetail from "@/utils/dataDetail";
import Cookies from "js-cookie";
import EventBus from '@/utils/EventBus';
import i18n from "../i18n"
import store from "./index"
let loading = false;
let toServiceFlag = false;
let giftConfigLoading = false;
let giftConfigReqTimes = 0;

function randName() {
  let name =
    String(dataDetail.rand(72, 99)) +
    dataDetail.rand(3, 9) +
    "****" +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10) +
    parseInt(Math.random() * 10);
  return name;
}

const user = {
  state: {
    shareUrl: "",
    systemConfig: {},
    banner: [],
    about: "",
    levels: [],
    unitData: [],
    chestNotice: [],
    oddsArr: [],
    goodsArr: [],
    giftRule: "",
    lang: ''
  },
  mutations: {
    SET_SYSTEMCONFIG(state, data) {
      state.systemConfig = { ...state.systemConfig, ...data }
      state.lang = data.lang
    },
    SET_SHAREURL(state, data) {
      state.shareUrl = data;
    },
    SET_BANNER(state, data) {
      state.banner = data;
    },
    SET_ABOUT(state, data) {
      state.about = data;
    },
    SET_LEVELS(state, data) {
      const res = [];
      for (let i = 0, len = data.length; i < len; i++) {
        const item = data[i];
        const pre = data[i - 1];
        if (!i) {
          res.push({ ...item, realValue: 20 });
          continue;
        }
        res.push({ ...item, realValue: pre.value });
      }
      state.levels = res;
    },
    SET_UNITDATA(state, data) {
      state.unitData = data;
    },
    SET_CHESTNOTICE: (state, data) => {
      state.chestNotice = [...state.chestNotice, ...data]
    },
    SET_GIFTCONFIG: (state, { odds, goods, rule }) => {
      state.oddsArr = odds;
      state.goodsArr = goods;
      state.giftRule = rule;
    },
  },
  getters: {
    shareUrl(state) {
      const url = state.shareUrl;
      if (!url) {
        store.dispatch("getShare")
      }
      return state.shareUrl;
    },
    systemConfig(state) {
      const config = state.systemConfig;
      if (!config.scroll_notice) {
        store.dispatch("getSystemConfig");
      }
      return state.systemConfig;
    },
    banner(state) {
      const arr = state.banner;
      if (!arr.length) {
        store.dispatch("getBanner");
      }
      return state.banner;
    },
    about(state) {
      const text = state.about;
      if (!text) {
        store.dispatch("getAbout");
      }
      return state.about;
    },
    levels(state) {
      const arr = state.levels;
      if (!arr.length) {
        store.dispatch("getLevels");
      }
      return state.levels;
    },
    unitData(state) {
      const arr = state.unitData;
      if (!arr.length) {
        store.dispatch("getUnitData");
      }
      return state.unitData;
    },
    blindNotice(state) {
      if (!state.chestNotice.length) {
        store.dispatch("initChestNotice")
      }
      return state.chestNotice;
    }
  },
  actions: {
    getGiftConfig({ commit }) {
      if (giftConfigLoading || giftConfigReqTimes > 5) return;
      giftConfigReqTimes++;
      return new Promise(async (resolve, reject) => {
        try {
          // const url = "/marketing/gift";
          const url = "/marketing/xydzp";
          const { data = {} } = await $get(url, {
            // act: 'list',
            // type: 'point'
          })
          const { odds = [], goods = [], rule = "" } = data;
          commit("SET_GIFTCONFIG", { odds, goods, rule })
          store.dispatch("initChestNotice");
          return resolve(data)
          // store.dispatch("verifyRes", data).then((res) => {
          // })
        } catch (error) {
          console.error('获取中奖列表错误:', error);
          giftConfigReqTimes--;
          return reject(error)
        }
      })
    },
    initChestNotice({ state, commit }, payload) {
      const odds = state.oddsArr;
      const goods = state.goodsArr;
      // if(!odds.length || !goods.length) return store.dispatch("getGiftConfig");
      if (!odds.length) return store.dispatch("getGiftConfig");
      const res = [];
      for (let i = 0; i < 128; i++) {
        // const flag = dataDetail.rand(0, 1);
        const flag = 1;
        const name = randName()
        if (flag) {
          const prizeIndex = dataDetail.rand(0, odds.length - 1);
          res.push({
            name,
            prize: odds[prizeIndex].title,
            text: `Chúc mừng khách hàng ${name} trúng thưởng ${odds[prizeIndex].title}`
          })
          continue;
        }
        // const goodIndex = dataDetail.rand(0, goods.length - 1);
        // res.push({
        //   name,
        //   prize: goods[goodIndex].title,
        //   text: `Chúc mừng khách hàng ${name} trúng thưởng ${goods[goodIndex].title}`
        // })
      }
      commit("SET_CHESTNOTICE", res)
    },
    getSystemConfig({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/other/config", { act: "system" });
          const chooseLanguage = Cookies.get("lang");
          if(!chooseLanguage && data.lang){
            i18n.locale = data.lang;
            EventBus.$emit('languageChanged', data.lang);
            Cookies.set("language", data.lang);
          }
          commit("SET_SYSTEMCONFIG", data)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getShare({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/other/config", { act: "share" })
          commit("SET_SHAREURL", data.text)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getBanner({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/content/slide", { position: "home" })
          commit("SET_BANNER", data || [])
        } catch (error) {
          dealError(error)
        }
      })
    },
    toService({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        const url = state.systemConfig.service_url;
        if (!!url) {
          return location.href = url;
        }
        try {
          const { data } = await $get("/other/config", { act: "system" })
          commit("SET_SYSTEMCONFIG", data);
          if (!!data.service_url) {
            location.href = data.service_url
          }
        } catch (error) {
          dealError(error)
        }
      })
    },
    download({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        const url = state.systemConfig.appurl_android;
        if (!!url) {
          return location.href = url;
        }
        try {
          const { data } = await $get("/other/config", { act: "system" })
          commit("SET_SYSTEMCONFIG", data)
          if (!!data.appurl_android) {
            location.href = data.appurl_android
          }
        } catch (error) {
          dealError(error)
        }
      })
    },
    getAbout({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/other/config", { act: "about" });
          !!data && !!data.about && commit("SET_ABOUT", data.about)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getLevels({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          console.log(1);
          const { data } = await $get("/users/ranks");
          commit("SET_LEVELS", data)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getUnitData({ commit }) {
      return new Promise(async (resolve, reject) => {
        if (loading) return
        loading = true;
        try {
          const { data } = await $get("/third/currency/rate");
          loading = false;
          commit("SET_UNITDATA", data);
        } catch (error) {
          loading = false;
          dealError(error)
        }
      })
    }
  },
}
export default user
