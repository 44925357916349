export default function (callback) {
  if (!(this instanceof Object)) {
    return
  }
  if (this instanceof Array) {
    for (let i = 0, len = this.length; i < len; i++) {
      const item = this[i];
      callback(item, i)
    }
    return
  }
  const keyArr = Object.keys(this)
  for (let i = 0, len = keyArr.length; i < len; i++) {
    const key = keyArr[i];
    const value = this[key];
    callback(key, value);
  }
}