const user = {
  state: {
    loginInfo: {},
    moneyObj: {},
    f5lodiing: false,
  },
  mutations: {
    SET_LOGIN_INFO: (state, loginInfo) => {
      state.loginInfo = loginInfo
    },
    SET_MONEY_INFO: (state, moneyObj) => {
      state.moneyObj = moneyObj
    },
    SET_f5LODIING: (state, f5lodiing) => {
      state.f5lodiing = f5lodiing
    },
  },
  actions: {
    login({ commit }, res) {
      commit('SET_LOGIN_INFO', res.loginInfo)
    },
    moneyInfo({ commit }, res) {
      commit('SET_MONEY_INFO', res.moneyObj)
    },
    pageLodiing({ commit }, res) {
      commit('SET_f5LODIING', res.f5lodiing)
    },
  },
}
export default user
