import Vue from 'vue'
import Router from 'vue-router'
import verConfig from './utils/verConfig'
import { getLanguage } from './i18n'
import store from './store'
import qs from "qs"
Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const pages = verConfig && verConfig.tabbar ? verConfig.tabbar.pages || [] : []
const tabbarRoutes = [];
for (let i = 0, len = pages.length; i < len; i++) {
  const page_item = pages[i];
  tabbarRoutes.push({ path: page_item.pagePath, component: page_item.component })
}

const lanNow = getLanguage()
const lans = require(`@/i18n/${lanNow}`).default;

const routes = [
  {
    path: '*',
    redirect: '/goods',
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/view/cart/index'),
    meta: {
      title: 'cart',
    },
  }, // 商城
  {
    path: '/order',
    name: 'order',
    component: () => import('@/view/cart/order'),
    meta: {
      title: '订单',
    },
  },
  {
    path: '/Message',
    name: 'Message',
    component: () => import('@/view/user/new/message'),
    meta: {
      title: 'Message'
    }
  },
  {
    path: '/Financing',
    name: 'Financing',
    component: () => import('@/view/Financing/index'),
    meta: {
      title: 'Financing',
    },
  },
  {
    path: '/Certification',
    name: 'Certification',
    component: () => import('@/view/new/certification/advanced'),
    meta: {
      title: 'Certification'
    }
  },
  {
    path: '/goods',
    name: 'goods',
    component: () => import('@/view/goods/index'),
    meta: {
      title: 'goods',
    },
  },
  // 首页下的子页面 - 等级详情
  {
    path: '/levelUpgrade',
    name: 'levelUpgrade',
    component: () => import('@/view/goods/levelUpgrade/levelUpgrade')
  },
  // 首页下的子页面 - 小饶
  {
    path: '/PlatformIntroduction',
    name: 'PlatformIntroduction',
    component: () => import('@/view/goods/Subpage/PlatformIntroduction'),
    meta: {
      title: 'PlatformIntroduction',
    },
  },
  {
    path: '/RuleDescription',
    name: 'RuleDescription',
    component: () => import('@/view/goods/Subpage/RuleDescription'),
    meta: {
      title: 'RuleDescription',
    },
  },
  {
    path: '/AgentCooperation',
    name: 'AgentCooperation',
    component: () => import('@/view/goods/Subpage/AgentCooperation'),
    meta: {
      title: 'AgentCooperation',
    },
  },
  {
    path: '/CommonProblem',
    name: 'CommonProblem',
    component: () => import('@/view/goods/Subpage/CommonProblem'),
    meta: {
      title: 'CommonProblem',
    },
  },
  // 首页下的子页面 - 小饶结束
  // 会员升级的页面 - 小饶
  {
    path: '/MemberUpgrade',
    name: 'MemberUpgrade',
    component: () => import('@/view/goods/Subpage/MemberUpgrade'),
    meta: {
      title: 'MemberUpgrade',
    },
  },
  // 会员升级的页面 - 小饶结束
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/index'),
    meta: {
      title: 'login',
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/view/login/forgot'),
    meta: {
      title: 'forgot',
    },
  },
  {
    path: '/registrasi',
    name: 'registrasi',
    component: () => import('@/view/login/registrasi'),
    meta: {
      title: 'registrasi',
    },
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/view/record/index'),
    meta: {
      title: 'record',
    },
  },
  // ========= 首页-子页面跳转（充值） ==========
  {
    path: '/Recharge',
    name: 'Recharge',
    component: () => import('@/view/goods/Recharge/Recharge'),
    meta: {
      title: 'Recharge',
    },
  },
  // ========= 首页-邀请好友 ==========
  {
    path: '/inviteFriends',
    name: 'inviteFriends',
    component: () => import('@/view/goods/inviteFriends/index'),
    meta: {
      title: 'inviteFriends',
    },
  },
  // {
  //   path: '/inviteFriends',
  //   name: 'inviteFriends',
  //   component: () => import('@/view/PersonalCenter/InviteFriends/InviteFriends'),
  //   meta: {
  //     title: 'inviteFriends',
  //   },
  // },
  // ========= 首页-子页面跳转（收回）========
  {
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import('@/view/goods/Withdraw/Withdraw'),
    meta: {
      title: 'Withdraw',
    },
  },
  // ========= 首页-子页面(客服) =========
  {
    path: '/Service',
    name: 'Service',
    component: () => import('@/view/goods/Service/Service'),
    meta: {
      title: 'Service',
    },
  },
  {
    path: '/DialoguePage',
    name: 'DialoguePage',
    component: () => import('@/view/goods/Service/DialoguePage'),
    meta: {
      title: 'Service',
    },
  },
  //========== 个人中心-小饶  ==========
  {
    path: '/user',
    name: 'user',
    component: () => import('@/view/user/index'),
    meta: {
      title: 'Personal'  // '个人',
    },
  },
  // =========== 理财—转出记录跳转 =======
  {
    path: '/RansferIn',
    name: 'RansferIn',
    component: () => import('@/view/Financing/RansferIn/RansferIn'),
    meta: {
      title: 'RansferIn' //'记录',
    },
  },
  {
    //========== 个人中心-设置-小饶  ==========
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: () => import('@/view/PersonalCenter/index'),
    meta: {
      title: 'PersonalCenter' //'个人中心',
    },
  },
  {
    //========== 个人中心-头像-小饶  ==========
    path: '/Portrait',
    name: 'Portrait',
    component: () => import('@/view/PersonalCenter/Portrait'),
    meta: {
      title: 'Portrait',
    },
  },
  {
    //========== 个人中心-名称-小饶  ==========
    path: '/Name',
    name: 'Name',
    component: () => import('@/view/PersonalCenter/Name'),
    meta: {
      title: 'Name',
    },
  },
  {
    //========== 个人中心-账户-小饶  ==========
    path: '/Account',
    name: 'Account',
    component: () => import('@/view/PersonalCenter/Account'),
    meta: {
      title: 'Account',
    },
  },
  {
    //========== 个人中心-密码-小饶  ==========
    path: '/Password',
    name: 'Password',
    component: () => import('@/view/PersonalCenter/Password'),
    meta: {
      title: 'Password',
    },
  },
  {
    //========== 个人中心-支付密码-小饶  ==========
    path: '/PaymentCode',
    name: 'PaymentCode',
    component: () => import('@/view/PersonalCenter/PaymentCode'),
    meta: {
      title: 'PaymentCode',
    },
  },
  {
    //========== 个人中心-修改地址-小饶  ==========
    path: '/Site',
    name: 'Site',
    component: () => import('@/view/PersonalCenter/Site'),
    meta: {
      title: 'Site',
    },
  },
  //========== 个人中心-账户详细资料-小饶 ==========
  {
    path: '/AccountData',
    name: 'AccountData',
    component: () => import('@/view/PersonalCenter/AccountData/AccountData'),
    meta: {
      title: 'AccountData',
    },
  },
  //========== 个人中心-充值记录-小饶 ==========
  {
    path: '/RechargeRecord',
    name: 'RechargeRecord',
    component: () =>
      import('@/view/PersonalCenter/RechargeRecord/RechargeRecord'),
    meta: {
      title: 'RechargeRecord',
    },
  },
  //========== 个人中心-提款记录-小饶 ==========
  {
    path: '/WithdrawalRecord',
    name: 'WithdrawalRecord',
    component: () =>
      import('@/view/PersonalCenter/WithdrawalRecord/WithdrawalRecord'),
    meta: {
      title: 'WithdrawalRecord',
    },
  },
  //========== 个人中心-邀请朋友-小饶 ==========
  {
    path: '/InviteFriends',
    name: 'InviteFriends',
    component: () =>
      import('@/view/PersonalCenter/InviteFriends/InviteFriends'),
    meta: {
      title: 'InviteFriends',
    },
  },
  //========== 个人中心-系统公告-小饶 ==========
  {
    path: '/Announcement',
    name: 'Announcement',
    component: () => import('@/view/PersonalCenter/Announcement/Announcement'),
    meta: {
      title: 'Announcement',
    },
  },
  //========== 个人中心-团队报告-小饶 ==========
  {
    path: '/TheTeamReport',
    name: 'TheTeamReport',
    component: () =>
      import('@/view/PersonalCenter/TheTeamReport/TheTeamReport'),
    meta: {
      title: 'TheTeamReport',
    },
  },
  //========== 个人中心-小饶  结束 ==========
  /**
   * 20201207
   * 玩家查询充值流水记录
   */
  {
    path: '/bill-record',
    name: 'BillRecord',
    component: () => import('@/view/PersonalCenter/RechargeRecord/billRecord'),
    meta: {
      title: 'billRecord',
    },
  },
  /**
   * new pages
   */
  {
    path: '/add-bank-card',
    name: 'BankCardAdd',
    component: () => import('@/view/bank/add-bank-card'),
    meta: {
      title: 'add-bank-card',
    },
  },
  {
    path: '/new-recharge',
    name: 'NewRecharge',
    component: () => import('@/view/bank/recharge'),
    meta: {
      title: 'recharge',
    },
  },
  {
    path: '/recharge-list',
    name: 'RechargeList',
    component: () => import('@/view/bank/recharge-list'),
    meta: {
      title: 'recharge-list',
    },
  },
  {
    path: '/new-withdraw',
    name: 'newWithdraw',
    component: () => import('@/view/bank/withdraw'),
    meta: {
      title: 'withdraw',
    },
  },
  {
    path: '/withdraw-list',
    name: 'withdrawList',
    component: () => import('@/view/bank/withdraw-list'),
    meta: {
      title: 'withdraw',
    },
  },
  {
    path: '/setting-pay-pwd',
    name: 'SettingPayPwd',
    component: () => import('@/view/bank/setting-pay-pwd'),
    meta: {
      title: 'setting-pay-pwd',
    },
  },
  {
    path: '/make-money',
    name: 'MakeMoney',
    component: () => import('@/view/new/make-money'),
    meta: {
      title: 'make-money',
    },
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/view/new/team'),
    meta: {
      title: 'team',
    },
  },
  {
    path: '/QRCodePay',
    name: 'qrPay',
    component: () => import('@/view/bank/qrcode'),
    meta: {
      title: 'qrcode-pay'
    } 
  },
  {
    path: '/BankPay',
    name: 'bankPay',
    component: () => import('@/view/bank/bank_pay'),
    meta: {
      title: 'bank-pay'
    }
  },
  {
    path: '/Game',
    name: 'game',
    component: () => import('@/view/new/game/index')
  },
  {
    path: '/Merchants',
    name: 'merchants',
    component: () => import('@/view/new/merchants')
  },
  {
    path: '/Activity',
    name: 'activity',
    component: () => import('@/view/new/activity')
  },
  {
    path: '/ActivityDetails',
    name: 'activityDetails',
    component: () => import("@/view/new/activity_details")
  },
  {
    path: '/OrderDetails',
    name: 'orderDetails',
    component: () => import("@/view/cart/details")
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("@/view/new/introduce")
  },
  {
    path: "/crypto",
    name: "crypto",
    component: () => import("@/view/new/crypto")
  },
  {
    path: "/ba",
    name: "ba",
    component: () => import("@/view/new/ba")
  },
  {
    path: "/walletList",
    name: "walletList",
    component: () => import("@/view/bank/wallet_list")
  },
  {
    path: '/Financial',
    name: 'financial',
    component: () => import('@/view/financial/financial'),
    meta: {
      title: 'Financial'
    }
  },
  {
    path: '/FinancialRecord',
    name: 'financialRecord',
    component: () => import('@/view/financial/financialRecord'),
    meta: {
      title: 'Financial Record'
    }
  },
  {
    path: '/TransferInto',
    name: 'TransferInto',
    component: () => import('@/view/financial/transferInto'),
    meta: {
      title: 'Transfer Into'
    }
  },
  {
    path: '/TransferOut',
    name: 'TransferOut',
    component: () => import('@/view/financial/transferOut'),
    meta: {
      title: 'Transfer Out'
    }
  },
  {
    path: "/Agent",
    name: "agent",
    component: () => import("@/view/agent")
  },
  {
    path: "/member",
    name: "member",
    component: () => import("@/view/agent/memberControl")
  },
  {
    path: "/memberDetails",
    name: "memberDetails",
    component: () => import("@/view/agent/memberDetails")
  },
  {
    path: "/TeamReport",
    name: "teamReport",
    component: () => import("@/view/agent/teamReport")
  },
  {
    path: "/AgentReport",
    name: "agentReport",
    component: () => import("@/view/agent/agentReport")
  },
  {
    path: "/ReportDetails",
    name: "reportDetails",
    component: () => import("@/view/agent/reportDetails")
  },
  {
    path: "/Commission",
    name: "commission",
    component: () => import("@/view/agent/commission")
  },
  {
    path: "/PhoneVerify",
    name: "phoneVerify",
    component: () => import("@/view/PersonalCenter/verifyPhone")
  },
  {
    path: "/GameRecord",
    name: "gameRecord",
    component: () => import("@/view/new/game_record")
  },
  {
    path: "/BlindBox",
    name: "blindBox",
    component: () => import("@/view/blindBox/index.vue")
  },
  {
    path: "/BlindBoxRecord",
    name: "blindBoxRecord",
    component: () => import("@/view/blindBox/records.vue")
  }
]

routes.push({
  path: "/tabbar",
  component: () => import("@/components/newTabbar"),
  children: tabbarRoutes
})

// add route path
routes.forEach((route) => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({
  routes,
  // mode:'history'
  mode: 'hash',
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = (lans.page && lans.page[to.name]) || title
  // }
  const pages = verConfig.tabbar
    ? verConfig.tabbar.pages || []
    : [];
  const currentPath = to.path;
  const pathArr = currentPath.split("/");
  const lastPath = pathArr[pathArr.length - 1];
  const tabbarIndex = pages.findIndex(item => item.pagePath == lastPath || item.pagePath == to.name)
  if (pathArr.indexOf("tabbar") !== -1 && tabbarIndex === -1) {
    return next(`/${lastPath}`);
  }
  for (let i = 0, len = pages.length; i < len; i++) {
    const item = pages[i];
    if (pathArr.indexOf("tabbar") === -1 && (item.pagePath === to.name || item.pagePath === lastPath || item.pagePath === to.path)) {
      return next(`/tabbar/${item.pagePath}?${qs.stringify(to.query || {})}`)
    }
    if (item.pagePath === lastPath) {
      store.commit("SET_ACTIVE", i)
    }
  }
  next()
})

export { router }
