class DataDetail {
  //数组扁平化
  flatten(arr){  
    if(!Array.isArray(arr)){
      console.log("typeof err!");
      return;
    }
    let res = [];
    for(let i=0;i<arr.length;i++){
      if(Array.isArray(arr[i])){
        res = res.concat(flatten(arr[i]));
      }else{
        res.push(arr[i]);
      }
    }
    return res;
  }
  //数组去重
  unique(arr){  
    if(!Array.isArray(arr)){
      console.log("typeof err!");
      return;
    }
    let res = [];
    for(let i=0;i<arr.length;i++){
      if(res.indexOf(arr[i]) === -1){
        if(arr[i]!=undefined){  //如果需要去除空数据就加这个判断,如果不需要就不加
          res.push(arr[i]);
        }
      }
    }
    return res;
  }
  // 数组去重方法2
  unique2(arr){
    if(!Array.isArray(arr)){
      console.log("typeof err!");
      return;
    }
    let tempObj = {};
    let newArr = [];
    arr.forEach(i => {
      tempObj[i] = i;
    });
    Object.keys(tempObj).forEach(j => {
      newArr.push(tempObj[j]);
    })
    return newArr;
  }
  //基础冒泡排序
  basicSort(arr){
    for(let i=0;i<arr.length-1;i++){
      for(let j=0;j<arr.length-1-i;j++){
        if(arr[j]>arr[j+1]){
          var temp = arr[j];
          arr[j] = arr[j+1];
          arr[j+1] = temp;
        }
      }
    }
  }
  // 冒泡排序方法2
  basicSort2(arr){
    let res = [];
    arr.forEach(i => {
      let index = res.length;
      for (let j = 0; j < res.length; j++) {
        if(i<res[j]){
          index = j;
          break;
        }
      }
      res.splice(index,0,i);
    })
    return res;
  }
  //JSON冒泡排序
  ownSort(arr){   
    Data=[
      {children:[{id:1}]},
      {children:[{id:5}]},
      {children:[{id:4}]},
      {children:[{id:11}]},
      {children:[{id:7}]},
      {children:[]}
    ]
    let res = [];
    for(let i=0;i<Data.length;i++){
      if(Data[i].children[0]===undefined){
        res.push(Data[i]);
        Data.splice(i,1);
      }
    }
    for(let j=0;j<Data.length-1;j++){
      for(let i=0;i<Data.length-1-j;i++){
        if(Data[i].children[0].id>Data[i+1].children[0].id){
          var temp = Data[i];
          Data[i] = Data[i+1]
          Data[i+1] = temp;
        }
      }
    }
    res = res.concat(Data)
    return res;
  }
  // 随机数
  rand(min,max,count){
    let res = [];
    if(count){
      for(let i=0;i<count;i++){
        res.push(randOne())
      }
    }else{
      res = randOne();
    }
    return res;
    function randOne(){
      return parseInt(Math.random()*(max-min),10)+min;
    }
  }
  // 随机排序
  randSort(arr){
    let res = []
    let old = Array.from(new Set(arr));
    let len = old.length;
    for(let i=0;i<len;i++){
      let index = this.rand(0,old.length);
      res[i] = old[index];
      old.splice(index,1);
    }
    return res;
  }
  //层级关系处理
  stratification(data){   
    let newArr = [];
    for(let i=0;i<old.length;i++){
      if(old[i].parent_id===0){
        old[i].parent_sid = old[i].label.substr(-1,1);
        old[i].children = [];
        newArr.push(old[i])
      }else if(old[i].parent_id===1||old[i].parent_id===2){
        old[i].parent_sid = old[i].label.substr(-3,3);
        for(let j=0;j<newArr.length;j++){
          if(old[i].parent_id==newArr[j].parent_sid){
            old[i].children = [];
            newArr[j].children.push(old[i])
          }
        }
      }else if(old[i].parent_id===3){
        old[i].parent_sid = old[i].label.substr(-3,3);
        for(let j=0;j<newArr.length;j++){
          for(let n=0;n<newArr[j].children.length;n++){
            if(newArr[j].children[n].parent_sid===old[i].parent_sid){
              newArr[j].children[n].children.push(old[i]);
            }
          }
        }
      }
    }
    for(let i=0;i<newArr.length;i++){
      delete newArr[i].parent_sid;
      delete newArr[i].parent_id;
      for(let j=0;j<newArr[i].children.length;j++){
        delete newArr[i].children[j].parent_sid;
        delete newArr[i].children[j].parent_id;
        for(let n=0;n<newArr[i].children[j].children.length;n++){
          delete newArr[i].children[j].children[n].parent_sid;
          delete newArr[i].children[j].children[n].parent_id;
        }
      }
    }
    return newArr;
  }
  //两数组求交集
  intersection(arr1,arr2){    
    if(!Array.isArray(arr1)){
      console.log("arr1 typeof err!");
      return;
    }
    if(!Array.isArray(arr2)){
      console.log("arr2 typeof err!");
      return;
    }
    let res=[];
    for(let i=0;i<arr1.length;i++){
      if(arr2.indexOf(arr1[i])!=-1){
        res.push(arr1[i]);
      }
    }
    return res;
  }
  //两数组求差集
  difference(arr1,arr2){    
    if(!Array.isArray(arr1)){
      console.log("arr1 typeof err!");
      return;
    }
    if(!Array.isArray(arr2)){
      console.log("arr2 typeof err!");
      return;
    }
    let res = [];
    for(let i=0;i<arr1.length;i++){
      if(arr2.indexOf(arr1[i])===-1){
        res.push(arr1[i]);
      }
    }
    return res;
  }
}
let dataDetail = new DataDetail;
export default dataDetail;