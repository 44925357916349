export default {
  // 公共的key
  common: {
    unrealized: '暂未实现',
    all: '全部',
    distributionTip: '0元起送 0配送费',
    easeBack: '安心退',
    addCarSuccess: '成功加入购物车',
    deleteConfirm: '确定要删除选中商品吗?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: '商品详情',
    shopping: '抢购中',
    end: '距结束',
    sold: '已售',
    goodsTips: '此商品按500g/份计价,如实收少于500g将退还差价',
    quickTime: '最快29分钟内送达',
    specifications: '规格',
    preservationConditions: '保存条件',
    coldStorage: '冷藏',
    shelfLife: '保质期',
    day: '15天',
    addToCar: '加入购物车'
  },
  //购物车
  car: {
    title: '购物车',
    delete: '删除',
    tip: '购物车空空滴~',
    walk: '去逛逛',
    all: '全选',
    guess: '猜你喜欢'
  },
  // 首页
  home: {
    HallofFame:'名人堂',
    chooseLocation: '请选择位置..',
    more: '更多',
    home: '首页',
    category: '分类',
    eat: '吃什么',
    cart: '购物车',
    mine: '我的',
    inputGoodsName: '输入商品名称',
    vipDesc: '加入会员·每年预计节省806元',
    vipSubDesc: '5折开卡>',
    specialZone: '特色专区',
    foodie: '吃货节',
    dinner: '晚餐',
    popularity: '人气',
    goodChoose: '心选',
    limitBuy: '限时抢购',
    bottomTip: '我是有底线的',
    chooseAddress: '请选择地址',
    currentLocation: '您当前的位置',
    nearbyAddress: '附近的地址'
  },
  // 分类
  category: {
    tip: '输入商品名称',
    bottomTip: '到底了,看看别的分类吧'
  },
  // 吃什么
  eat: {
    all: '全部',
    close: '收起',
    pleaseHolder: '搜索菜谱、食材',
    menu: '今日菜单',
    menuDevide: '菜单分类',
    recent: '最近找过',
    tip_bottom: '到底了,别拉了!',
    tip: '收藏暂未实现哦~'
  },
  // 我的
  mine: {
    title: '我的',
    phone: '手机号',
    login: '立即登录',
    myOrder: '我的订单',
    allOrder: '查看全部订单',
    myBill: '我的优惠券',
    myLocation: '我的收货地址',
    myCar: '我的绿卡',
    servier: '联系客服',
    servierTime: '客服时间 07:00-22:00',
    feedback: '意见反馈',
    switchLanguage: '语言',
    chinese: '中文',
    english: '英文',
    version: '当前版本',
    waitingPay: '待支付',
    waitingReceive: '待收货',
    waitingComment: '待评价',
    waitingFeedback: '售后/退款',
    unrealized: '退款页面尚未开通啦!',
    tip2: '记得点个小星❤️哦~',
    tip3: '💘感谢您的关注💘',
    tip4: 'GitHub上搜索 \nGeek-James/ddBuy \n🦉欢迎提出优化建议🙉',
    feedbackTip: '暂时还没有相关订单',
    cardPrise: '绿卡价格',
    moreMessage: '更多特权敬请期待...',
    openCard: '开通绿卡',
    recomend: '开通绿卡享三大权益,预计每单省6.66元',
    cardTip1: '专享券',
    cardTip2: '专享特价',
    cardTip3: '2倍积分',
    cardTip4: '更多特权',
    vipType: '5折',
    vipTime: '年卡·365天',
    vipRecommend: '相当于约0.24元/天',
    prise1: '¥88',
    prise2: '¥188',
    vipType1: '7折',
    vipTime1: '季卡·90天',
    vipRecommend1: '相当于约0.33元/天',
    prise11: '¥30',
    prise12: '¥45',
    payMethod: '支付方式',
    wechatPay: '微信支付',
    aliPay: '支付宝支付',
    huabeiPay: '花呗支付',
    payImmeatally: '立即支付',
    cardUeless: '绿卡未开通',
    tip: '只能到这步啦!后面的臣妾做不到啊~~',
    changeNickName: '修改昵称',
    confirm: '保存',
    nickName: '请输入新昵称',
    personal: '个人资料修改成功!',
    message: '请输入昵称',
    myBills: '我的优惠券',
    persent: '请输入520it兑换',
    bill: '优惠券 ',
    condition: '无使用门槛\n最多优惠1.5元',
    rmb: '元',
    myOrders: '我的订单',
    itemsTitle: ['全部', '待支付', '待收货', '待评价'],

    personalInfo: '个人资料',
    head: '头像',
    user_name: '昵称',
    userSex: '性别',
    Brithday: '生日',
    phoneNumber: '手机号',
    noInput: '未填写',
    logout: '退出登录',
    infoTip: '个人资料修改成功',
    loginInfo: '确定退出登录吗?',
    infoSuccess: '退出成功',
    year: '年',
    month: '月',
    day: '日',
    man: '帅哥',
    woman: '美女',

    greenCard: '绿卡',
    greenCardPower: '绿卡特权',
    fiftypecert: '5折开通绿卡',
    cardRecoamnd: '绿卡专享券 天天领取优惠',
    TodayExclusivecoupon: '今日专享券',
    everyUpadate: '每天0点更新',
    to: '满',
    toUse: '元使用',
    immeatallyGet: '立即领取',
    weekBill: '本周专享券',
    cardTip7: '立即领取',
    billTip: '绿卡专享券 天天领取优惠',
    currentGoods: '您当前购物',
    cardShop: '开通绿卡购物',
    cardBack: '返积分为',
    cardSpecialTip: '立即开启积分加速',
    cardSpecial: '绿卡专享特价',
    cardPriseb: '180元',
    cardPrisea: '88元',
    yearCard: '年卡',
    openCards: '开通绿卡',
    onece: '1倍',
    twice: '2倍'
  },
  // 订单
  order: {
    outTimeGetMoney: '超过十分钟可获得积分补偿',
    inputForm: '填写订单',
    location: '选择收货地址',
    arrivalTime: '送达时间',
    total: '共',
    thing: '件',
    wechatPay: '微信支付',
    aliPay: '支付宝支付',
    huabeiPay: '花呗支付',
    use: '使用',
    order12: '积分兑换',
    mark: '备注',
    tip: '选填,可以告诉我们您的特殊需求',
    goodsList: '商品清单',

    totalMoney: '商品金额',

    sendMoney: '配送费',
    point: '积分',
    order19: '实付',

    pay: '实付',
    sendForm: '提交订单',

    selectArrivalTime: '选择送达时间',
    comfirm: '确定',
    goods: '商品',
    all: '共',
    things: '件',
    sigalPrice: '单价:',
    numbers: '数量:',
    addTip: '还没有添加过地址呢,添加一个吧😄',

    addLocation: '添加地址',
    comfirmAndUse: '保存并使用',
    editLocation: '编辑地址',
    myAddress: '我的地址',
    settlement: '结算',
    chooseGoods: '请选择需要结算的商品',
    deliveryTime: '请选择送达时间',
    today: '今天',
    tomorrow: '明天'
  },
  Login: {
    indexTitle: '登录',
    Daftar: {
      title: '注册',
      phone: '请输入您的手机号码',
      code: '请输入验证码',
      password: '请输入密码',
      againPassword: '请再次输入密码确认'
    },
    Forgot: {
      sendCode: '发送',
      confirm: '提交',
      goBack: '返回'
    },
    nameInput: '请输入您的手机号码',
    pwdInput: '请输入密码',
    remenber: '记住密码',
    loginButton: '登录',
    registrasi: '注册'
  },
  // 登录
  login: {
    title: '登录',
    phoneNumber: '手机号',
    phoneNumberNotCorrect: '手机号格式不正确',
    phoneNumberNotEmpty: '手机号不能为空',
    passNumberNotEmpty: '密码不能为空',
    phoneInput: '请输入手机号',
    pass: '密码',
    passTip: '请输入密码',
    varify: '请输入验证码',
    otherMethods: '其他登录方式',
    wechat: '微信登录',
    qqchant: 'QQ登录',
    tipTile: '温馨提示：',
    tipContent: '未注册的手机号，登录时将自动注册，且代表同意',
    tip: '用户协议',
    tipProcy: '隐私策略',
    login: '登录',
    resgin: '注册',
    passTip2: '请输入密码(不少于6位)',
    sendVerify: '发送验证码',
    hasSend: '已发送',
    phoneVerify: '账号密码登录',
    smsMessage: '短信验证码登录',
    switchLoginMsg: '账号密码登录',
    messageSuccess: '验证码获取成功,请在输入框输入',
    pleaseInputCorrectPhoneNumber: '请输入正确的手机号',
    pleaseInputCorrectVerifyumber: '请输入正确的验证码',
    otherWechat: '微信登录-暂未完成',
    otherQQ: 'QQ登录-暂未完成',
    message: '验证码:'
  },
  Hint: {
    notMore: '没有更多了',
    loading: '加载中',
    failed: '失败',
    SendCodeSuccess: '验证码已发送成功!',
    phoneNot: '手机号/账号不能为空',
    pwdInconformity: '两次密码输入不一致',
    notInput: '输入内容不完整',
  },
  newTab: {
    home: '首页',
    money: '充值',
    order: '订单',
    my: '我的',
    team:'团队'
  },
  tab: {
    make_money: '接单'
  },
  Cart: {
    zzInfo: {
      buyIng: '购买中',
      buyFailed: '购买失败',
      notLevel: '您的会员等级不足',
      buyJurisdiction1: '如需购买黄金会员订单',
      buyJurisdiction2: '如需购买白金会员订单',
      buyJurisdiction3: '如需购买钻石会员订单',
      invite1: '邀请3个好友一起赚钱',
      invite4: '邀请10个好友一起赚钱',
      invite3: '邀请30个好友一起赚钱',
      invite2: '邀请好友',
      myMoney: '您的账户余额',
      under: '低于',
      notSendMoney: '不能进行交易',
      goTopUp: '请前往充值',
      know: '我知道了',
      account: '结算'
    },
    hint: {
      p1: '1. 不同的会员级别有不同的购买订单',
      p2: '2. 会员级别越高，可购买的订单佣金金额越高',
      p3: '3. 对于已购买的订单，您可以随时监控订单状态'
    },
    state: {
      all: '全部',
      going: '进行中',
      paid: '已支付',
      frozen: '已冻结',
      over: '已完成',
      canceled: '已取消'
    },
    info: {
      allMoney: '我的总资产 ฿',
      dayAllOrder: '今天的总订单',
      dayTrustMoney: '今天委托金',
      yesterdayMoney: '昨天的收入'
    },
    text: {
      one: '订单'
    }
  },
  My: {
    download: '下载APP',
    personal: '个人信息',
    recharge: '充值记录',
    withdrawal: '提现',
    language: '语言设置',
    logout: '登出',
    MemberInformation: {
      balance: '账户余额',
      Withdraw: '提现',
      Recharge: '充值',
      forzenAll: '冻结总额'
    },
    PersonalInformation: {
      Page: {
        Avatar: '头像',
        Nickname: '昵称',
        PhoneNumber: '电话号码',
        BankAccounts: '银行卡',
        Password: '密码',
        title: '个人信息'
      },
      portrait: {
        SetAvatar: '头像设置',
        button: '修改'
      },
      Name: {
        ModifyNickname: '修改昵称',
        title: '修改昵称',
        name: '昵称',
        Please: '请输入您想修改的昵称'
      },
      Password: {
        title: '修改登录密码',
        PleaseEnter: '请输入您想修改的密码',
        phone: '手机号码',
        NewPhone: '请输入您的手机号码',
        verificationCode: '验证码',
        verificationCodeNote: '请输入验证码',
        ConfirmPassword: '密码',
        ConfirmPasswordNote: '请输入您想修改的密码',
        Text: '请记住密码，如果忘记密码，请联系客服。',
        Button: '确认修改'
      },
      AccountData: {
        title: '交易详情'
      }
    }
  },
  newAdd: {
    all: '全部',
    Completed: '已完成',
    unfinished: '未完成',
    Income: '收入',
    Expenditure: '支出',
    GetCodeBtn: '获取验证码',
    copySuccess: '复制成功',
    copyError: '复制失败',
    noLoginUserName: '请登录',
    Withdraw: '提现',
    Recharge: '充值',
    commission: '佣金',
    single: '单',
    missionHall: '任务大厅',
    videoTutorial: '视频教程',
    helpCenter: '帮助中心',
    inviteFriends: '邀请好友',
    help1Title: '如何通过CG赚取佣金',
    help1Context: '电商老板需要添加店铺的销售额,所以我们只需要在CG上完成电商店铺的要求,完成订单即可。业主会给我们佣金。每个用户每天可以接收10个订单任务。您可以在一天内赚取 10% 到 30% 的佣金。',
    help2Title: '如何增加任务数量',
    help2Context: '由于兼职员工太多，公司规定所有在线兼职员工使用唯一的whatsapp进行联系和接收任务',
    help3Title: '如何完成任务',
    help3Context: '帮助大商家增加销售，并根据交易数量奖励你。对于每一个成功的订单，你赚取佣金，卖家赢得声誉。',
    referralLink: '推荐链接',
    copy: '复制',
    blance: '余额',
    todayOrder: '今日订单',
    provided: '本数据由提供',
    Transaction: '交易',
    Frozen: '冻结',
    Done: '完成',
    Cancel: '取消',
    ExpectedReturn: '预计返还:',
    ImmediatePayment: '立即支付',
    AvailableNum: '可用次数',
    OrderImmediately: '马上配单',
    MakeMoneyEveryDay: '日日赚钱',
    TodayRecord: '今日记录',
    AllocatCommissions: '分配佣金',
    FrozenAmount: '冻结金额',
    TaskMatch: '任务分配',
    AvailableBalance: '可用余额',
    WaitDispatch: '等待分配任务,请联系店长!',
    OrderDescription: '今日记录',
    // CouldMatch: '每个账户每天可配',
    CouldMatch: '任务由系统随机匹配',
    OrderPerDay: '单。',
    DescriptionRate: '配单佣金统一为订单金额的',
    DescriptionPeriod: '。',
    Description3: '系统基于LBS技术, 通过云端自动匹配商品。',
    Description4: '为防止平台监控,如果订单为在20分钟内确认并发送,系统将冻结订单数量。',
    Note: '注意事项:',
    DescriptionNote: '当平台为用户匹配订单时, 平台将订单信息提交给商家后台。如果用户在20分钟内未提交订单，\
    订单金额将被系统冻结。为避免监管系统，会对用户账号进行评分，直接影响用户下一次撮合的\
    时间和口碑！如有其他需要处理的，请及时点击停止下单，以免超时冻结',
    OrderNum: '订单号:',
    TotalOrderPrice: '总价:',
    SubmitNow: '立即提交',
    Record: '记录',
    MemberAccount: '会员账户',
    AccountPlaceholder: '请输入您的账户',
    RechargeAmount: '充值金额',
    RechargeAmountPlaceholder: '请输入充值金额',
    SelectRechargeMethod: '选择充值方式',
    NotBeenSentOrder: '尚未派发订单,请联系工作人员派发订单!',
    TodayOrderOver: '今日订单已完成,请明天再试!',
    NotLogin: '您尚未登录,请登录后重试!',
    HaveNoteMethod: '暂时没有这样的充值方法,请选择其他充值方法~',
    Login: '登录',
    AccountRecord: '账变记录',
    RealName: '真实姓名',
    IFSCCODE: 'IFSC',
    BankName: '银行卡名称',
    BankNum: '银行卡号', 
    Mobile: '手机',
    MobileNum: '手机号码',
    Email: '邮箱',
    EmailRequired: '邮箱必填',
    AddBankCardNote: '银行卡是重要信息, 请仔细填写!',
    Submit: '提交',
    AddBankCardTitle: '添加银行卡',
    BankCard: '银行卡',
    Name: '姓名',
    CardNum: '卡号',
    Reading: '正在读取中...',
    AllBalance: '总余额',
    EnterWithdrawalAmount: '请输入提现金额',
    AllOut: '全部',
    AskNow: '提现',
    EnterPwdPlaceholder: '请输入密码',
    NotHaveCard: '你尚未绑定银行卡,您要去绑定银行卡吗?',
    GetBankInfoFail: '拉取银行信息失败',
    EnterValidPayPwd: '请输入有效的支付密码',
    Balance: '余额',
    Total: '总共',
    total: '总共',
    records: '记录',
    DisplayPerPage: '每页显示',
    NullNum: '条',
    pages: '页。',
    toAudit: '待审核',
    succeed: '成功',
    RechargeRecordTitle: '充值记录',
    rests: '其他',
    Arrive: '到账',
    forzenCommission: '冻结佣金',
    companyIntro: '公司简介',
    companyTitle: '塔塔购物中心是塔塔集团的一部分',
    companyText: '塔塔集团是印度最大的商业集团，成立于1868年，总部设在印度孟买。业务运作涉及七个业务领域:通信和信息技术、工程、材料、服务、能源、消费品和化工产品。作为印度快速发展的商业集团，塔塔集团在六大洲80多个国家拥有200多家子公司，产品和服务出口到85个国家。塔塔集团2020-2021财年的总收入为1009亿美元，其中58%来自海外业务。集团在全球拥有约45万名员工。自153年以来，塔塔这个名字在印度一直受到广泛尊重，因为它坚持良好的价值观和商业道德。在福布斯全球最受尊敬的公司中排名第11位。',
    depositAddress: '充值地址',
    rechargeSteps: '充值步骤',
    completePayment: '充值完成',
    rechargeNow: '立即充值',
    rechargeCancel: '取消充值',
    bankName: '银行名称',
    payee: '收款人',
    cardNum: '收款账户',
    reviewing: '审核中',
    remittance: '已汇款',
    notThrough: '未通过',
    OrderID: '订单ID',
    toCutScreen: "如果图片不可用请进行截屏!"
  },
  add:{
    Commission:'今日冻结金额', 
    Yesterday:'昨日完成收益',
    Cumulative:'历史累计首日',
    Today:'今日预计收益',
    TeamNum:' 已邀请有效人数',
    TeamMoney:'已获取收益',
    Personalcenteraccountchangerecord:'变账记录'
  },
  "Merchant": '玩法',
  "all": "所有",
  "btn.recharge": "充值",
  "btn.withdraw": "提现",
  "btn.service": "客服",
  "merchant.all": "所有玩法",
  "merchant.hot": "热门玩法",
  "merchant.list": "玩法列表",
  "tab.merchants": "玩法",
  "orderDeadlineText": "订单截止日期",
  "orderResultText": "匹配结果",
  "status.waiting": '待开奖',
  "status.done": "已开奖",
  "status.fail": '开奖错误',
  "status.open": '开奖中...',
  "open.big": '大',
  "open.small": '小',
  "open.single": '单',
  "open.double": '双',
  "btn.clear": "清空",
  "btn.match": "匹配",
  "hint.moneyLess": "余额不足!",
  'hint.opening': "关闭期间不允许下单!",
  "hint.matchSuccess": "下单成功!",
  "hint.matchFail": "下单失败!",
  "popup.title.buyConfirm": "确认订单",
  'popup.text.data': '数据',
  'yuan': '元',
  'popup.title.matchAmount': '匹配金额',
  'popup.title.matchContent': '匹配内容',
  "hint.confirmBuy": "请在期限内完成订单确认，以免错过订单!",
  "popup.btn.match": "匹配",
  'hint.toAddOrder': '请选择要下注的号!',
  'hint.toPerPrice': "请输入下注金额!",
  "popup.title.matchResult": "匹配结果",
  'popup.orderId': "订单号",
  "tab.activity": "活动",
  "toDetails": "查看详情",
  "activity.title": "活动名称",
  "activity.time": "活动时间",
  "nav.activityDetails": "活动详情",
  "order.status.all": "所有",
  "order.status.waiting": '待开奖',
  "order.status.done": "已开奖",
  "order.typeTitle": "玩法",
  "order.totalPrice": "下注金额",
  "order.winPrice": "赢",
  "order.status": "状态",
  "nav.orderDetails": "订单详情",
  "orderDetails.title.time": "下注时间",
  "orderDetails.title.type": "玩法类型",
  "orderDetails.title.expect": "订单号",
  "orderDetails.title.method": "玩法",
  "orderDetails.title.result": "结果",
  "orderDetails.title.inType": "结果",
  "orderDetails.title.odds": "赔率",
  "orderDetails.title.inPerPrice": "下注单价",
  "orderDetails.title.inNum": "下注数量",
  "orderDetails.title.winAmount": "赢",
  "game.perPrice.text": "每单",
  "balance.text": "余额",
  "input.permoney.placeholder": "输入金额",
  "user.tools.introduce": "介绍",
  "user.tools.crypto": "加密货币",
  "user.tools.ba": "币安",
  "page.walletList": "钱包列表",
  addWallet: "添加",
  "walletTypeBank": "银行",
  "walletTypeUSDT": "USDT",
  "btn.editWallet": "修改",
  agent: {
    title: {
      memberControl: "会员管理",
      share: "分享推荐链接",
      agentRead: "代理政策",
      betInfo: "投注信息",
      paymentInfo: "支付信息",
      teamInfo: "团队报告",
      daily: "每日",
      agent: "代理",
      agentCommission: "代理佣金"
    }
  },
  'My.financial': "理财",
  "page.financial": "理财",
  "page.financialRecord": "理财记录",
  "hint.InternetErr": "网络错误，请稍后再试!",
  "totalDeposit": "总存款",
  "totalRevenue": "总收入",
  "btn.transferInto": "转入",
  "btn.transferOut": "转出",
  "page.transferInto": "转入",
  "page.transferOut": "转出",
  "balanceTransfer": "可转出余额",
  "availableBalance": "可用余额",
  "depositRate": "存款利率",
  "days": "天",
  "hint.success": "成功",
  "hint.fail": "失败",
  "status.into": "转入",
  "status.out": "转出",
  "label.amount": "数量",
  "label.time": "时间",
  "my.vip": "VIP 特权",
  "my.verifyPhone": "验证手机号码",
  "my.modifyPass": "改密码",
  "my.wallet": "钱包",
  "my.betRecord": "投注记录",
  page: {
    member: "成员",
    teamReport: "团队报表",
    agentReport: "代理报表",
    agentCommission: "代理佣金",
    verifyPhone: "手机号码验证",
    withdrawRecord: "提现记录",
    games: "游戏",
    openRecord: "开奖记录"
  },
  placeholder: {
    member: "搜索成员"
  },
  btn: {
    search: "搜索",
    sort: {
      level: "等级",
      indate: "注册时间"
    },
    confirm: "确定",
    cancel: "取消"
  },
  member: {
    title: {
      level: "等级"
    },
    label: {
      indate: "注册时间",
      loginData: "登录时间",
      superior: "上级",
      member: "成员"
    },
    notLogin: "未登录",
    toDetails: "查看详情",
  },
  memberDetails: {
    tabs: {
      teamInfo: "团队统计",
      lower: '下级'
    },
    lowerMember: "下级成员",
    teamBalance: "团队余额",
    ownBalance: "个人余额",
    personalRecord: "个人记录",
    teamRecord: "团队记录",
    btn: {
      today: "今天",
      yesterday: "昨天",
      thisMonth: "这个月",
      lastMonth: "上个月"
    },
    table: {
      personalBalance: "{Username}的余额",
      childrenNum: "{Username}的下级人数",
      rechargeAmount: "{Username}的充值金额",
      withdrawAmount: "{Username}的提现金额",
      betAmount: "{Username}的下注金额",
      winAmount: "{Username}赢得的金额",
      groupBalance: "团队余额",
      teamProfit: "团队收益",
      groupRegNum: "团队注册人数",
      groupBetUsers: "团队下注人数",
      groupBetAmount: "团队下注金额",
      groupWinNum: "团队赢之人数",
      groupWinAmount: "团队赢之金额",
      groupRechargeAmount: "团队充值金额",
      groupWithdrawAmount: "团队提现金额",
      betCommission: "下注佣金",
      groupBetCommission: "团队下注佣金"
    }
  },
  teamReport: {
    from: "从",
    to: "至",
    quickSelect: "快速选择",
    groupBalance: "团队余额",
    label: {
      membersNum: "成员总数",
      rechargeAmount: "总存款",
      withdrawAmount: "总提现",
      betNum: "下注人数",
      betAmount: "总下注",
      winNum: "赢之人数",
      winAmount: "总赢取",
      commission: "佣金"
    }
  },
  agentReport: {
    searchPlaceholder: "搜索代理名字...",
    label: {
      total: "所有",
      recharge: "充值",
      withdraw: "提现",
      bet: "总下注",
      commission: "佣金",
      admin: "管理员调整",
      win: "赢",
      promotion: "优惠",
      profit: "利润"
    },
    formula: "(*) 利润 = 总赢取 + 奖金 + 佣金"
  },
  phoneVerify:{
    label:{
      code: "验证码"
    },
    placeholder: {
      code: "请输入验证码"
    },
    btn: {
      send: "发送",
      confirm: "验证"
    }
  },
  game: {
    rule: {
      title: "介绍",
      close: "关闭"
    }
  },
  "open.result": '结果',
  "open.hz": "和值",
  "open.openTime": "开奖时间"
}
