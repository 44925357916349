/*
 * @Author: your name
 * @Date: 2022-04-22 16:04:09
 * @LastEditTime: 2022-04-26 14:28:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \app\src\main.js
 */
import Vue from 'vue'
import App from './App'
import store from './store'
import { router } from './router'
// import i18n from './i18n/index'
import i18n, { getLanguage } from "./i18n"
import VueI18n from 'vue-i18n'
import Module from './utils/Module'
import './styles/index.css'
import VueClipboard from 'vue-clipboard2'
import '@/assets/iconfonts/iconfont.css'
import { Api } from '@tt/api'
import EventBus from '@/utils/EventBus';
import languageMixin from '@/utils/languageMixmin';

Vue.mixin(languageMixin);
Vue.prototype.$eventBus = EventBus;

Vue.use(VueClipboard)
import map from './utils/map'
Vue.prototype.$map = map;

import { cookie } from '@/utils/tools'

import { Loading } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import verConfig, { ver } from '@/utils/verConfig'
import { Toast } from 'vant'
import format from "@/utils/dateFormat"
import moneyFormat from "@/utils/formatMoney"
import ImageFormat from "@/utils/ImgFormat"

Toast.setDefaultOptions('loading', { forbidClick: true });
const isDownloadFn = true
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Loading)
Vue.prototype.module = Module
Vue.prototype.$cookie = cookie
Vue.prototype.$api = Api;
Vue.prototype.$store = store;
Vue.prototype.$isDownloadFn = isDownloadFn;
Vue.prototype.verConfig = verConfig;
Vue.prototype.ver = ver;
Vue.prototype.$format = format;
Vue.prototype.$money = moneyFormat;
Vue.prototype.$img = ImageFormat;
Vue.prototype.lan = getLanguage();

Vue.filter("money", (val) => moneyFormat(val))
Vue.filter("img", (val) => ImageFormat(val))

// 动态更改网站标题
document.getElementsByTagName('title')[0].innerText = verConfig.title;
// 动态更改网站图标
const icoLink = document.createElement('link');
icoLink.type = 'image/png';
icoLink.rel = 'icon';
const icoVer = ver;
icoLink.href = require(`@/assets/ico/${icoVer}.png`);
document.head.appendChild(icoLink);

setStyles();

function setStyles() {
  const root = document.querySelector(':root');
  for (const key in verConfig.themes) {
    if (Object.hasOwnProperty.call(verConfig.themes, key)) {
      const val = verConfig.themes[key];
      root.style.setProperty(key, val);
    }
  }
}

Vue.use(VueI18n, {
  i18n: (key, value) => i18n.t(key, value),
})

new Vue({
  router,
  store,
  i18n,
  el: '#app',
  render: (h) => h(App),
})
