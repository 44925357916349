// const componentsContext = require.context("./src", true, /\.js$/);
// const res = {};
// componentsContext.keys().forEach(componentPath => {
//   const componentKey = componentPath.replace(/^.\/(.*)\.\w+$/, "$1");
//   res[componentKey] = componentsContext(componentPath).default;
// });
// module.exports = res;

export * from "./src/ImgData";
export { default as $img } from "./src/ImgFormat";
export * from "./src/Module";
export * from "./src/anti-shake";
export * from "./src/dataDetail";
export * from "./src/dataDetails";
export * from "./src/dateFormat";
export * from "./src/index";
export * from "./src/keepDigits";
export * from "./src/map";
export * from "./src/newReqAxios";
export * from "./src/newRequest";
export * from "./src/quickSelectDate";
export * from "./src/random";
export * from "./src/randomUsername";
export * from "./src/request";
export * from "./src/tools";