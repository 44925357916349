export default {
    // 公共的key
    common: {
      unrealized: 'Henüz uygulanmadı',
      all: 'tüm',
      distributionTip: '0 yuan' + `'` + 'dan 0 teslimat ücreti almak için',
      easeBack: 'İçiniz rahat olsun',
      addCarSuccess: 'Sepete başarıyla eklendi',
      deleteConfirm: 'Seçili öğeyi silmek istediğinizden emin misiniz?'
    },
    //商品详情
    goodsDetail: {
      goodsDetail: 'Ürün ayrıntıları',
      shopping: 'Çıtlatma',
      end: 'sondan',
      sold: 'Satılan',
      goodsTips: 'Bu ürün 500g / kopya olarak fiyatlandırılmıştır ve 500g' + `'` + 'den az alınırsa, fark iade edilecektir',
      quickTime: 'En erken 29 dakika içinde teslimat',
      specifications: 'şartname',
      preservationConditions: 'Koşulları kaydet',
      coldStorage: 'Soğutma',
      shelfLife: 'Raf ömrü',
      day: '15 gün',
      addToCar: 'Sepete ekle'
    },
    //购物车
    car: {
      title: 'Alışveriş sepeti',
      delete: 'Silmek',
      tip: 'Alışveriş arabası boş.',
      walk: 'Yürüyüşe çıkın',
      all: 'Tümlerini seç',
      guess: 'Sanırım hoşuna'
    },
    // 首页
    home: {
      HallofFame:'Hall of Fame',
      chooseLocation: 'Lütfen bir konum seçin..',
      more: 'daha',
      home: 'Ev',
      RP:'try',
      category: 'ayırmak',
      eat: 'Ne yenir',
      cart: 'Alışveriş sepeti',
      mine: 'O benim.',
      inputGoodsName: 'Ürün adı girme',
      vipDesc: 'Üyenin yıllık tasarrufunun 806 yuan olması bekleniyor',
      vipSubDesc: 'Kart açılışında %50 indirim>',
      specialZone: 'Öne çıkan bölge',
      foodie: 'Foodie Festivali ',
      dinner: 'akşam yemeği',
      popularity: 'Popüler -lik',
      goodChoose: 'Bayıldım',
      limitBuy: 'Sınırlı bir zaman acelesi',
      bottomTip: 'Bir sonuç var',
      chooseAddress: 'Lütfen bir adres seçin',
      currentLocation: 'Geçerli konumunuz',
      nearbyAddress: 'Yakındaki adresler'
    },
    // 分类
    category: {
      tip: 'Ürün adı girme',
      bottomTip: 'Sonuçta, diğer kategorilere bakın'
    },
    // 吃什么
    eat: {
      all: 'tüm',
      close: 'Çökmek',
      pleaseHolder: 'Tarifleri arayın、Malzemeler',
      menu: 'Bugünün menüsü',
      menuDevide: 'Menü kategorileri',
      recent: 'Son zamanlarda aradı',
      tip_bottom: 'Günün sonunda,Çekme!',
      tip: 'Koleksiyon henüz gerçekleşmedi Oh ~'
    },
    // 我的
    mine: {
      title: 'O benim',
      phone: 'Cep telefonu numarası',
      login: 'Şimdi oturum açın',
      myOrder: 'Emirlerim',
      allOrder: 'Tüm siparişleri görüntüle',
      myBill: 'Kuponlarım',
      myLocation: 'Kargo adresim',
      myCar: 'Yeşil kartım',
      servier: 'Müşteri hizmetleriyle iletişime geçin',
      servierTime: 'Müşteri hizmetleri saatleri 07:00-22:00',
      feedback: 'Geri besleme',
      switchLanguage: 'Dil',
      chinese: 'Çince',
      english: 'İngilizce',
      version: 'Geçerli sürüm',
      waitingPay: 'Bekleyen ödeme',
      waitingReceive: 'Alınacak',
      waitingComment: 'Değerlendirilecek',
      waitingFeedback: 'Satış sonrası/Iade',
      unrealized: 'Geri ödeme sayfası henüz açılmadı!',
      tip2: 'hatırlamak tık küçük ❤️ Oh~',
      tip3: '💘İlginiz için teşekkür ederiz💘',
      tip4: 'GitHub' + `'` + 'da ara \nGeek-James/ddBuy \n🦉Optimizasyon önerileri bekliyoruz🙉',
      feedbackTip: 'Şu anda ilgili bir emir yok',
      cardPrise: 'Yeşil kart fiyatı',
      moreMessage: 'Yakında daha fazla ayrıcalık geliyor...',
      openCard: 'Yeşil kart açma',
      recomend: 'Yeşil kart açma zevk almak Üç ana çıkar,Tek başına 6.66 yuan tasarruf etmesi bekleniyor',
      cardTip1: 'Özel kuponlar',
      cardTip2: 'Özel spesiyaller',
      cardTip3: '2x puan',
      cardTip4: 'Daha fazla ayrıcalık',
      vipType: '%50 indirim',
      vipTime: 'Yıllık Geçiş·365 gün',
      vipRecommend: 'Yaklaşık eşdeğer 0.24 yuan / gün',
      prise1: '¥88',
      prise2: '¥188',
      vipType1: '%30 indirim',
      vipTime1: 'Sezon kartı·90 gün',
      vipRecommend1: 'Yaklaşık eşdeğer 0.33 yuan / gün',
      prise11: '¥30',
      prise12: '¥45',
      payMethod: 'Ödeme Yöntemleri',
      wechatPay: 'WeChat Ödeme',
      aliPay: 'Alipay ödemesi',
      huabeiPay: 'Huabei ödemesi',
      payImmeatally: 'Şimdi öde',
      cardUeless: 'Yeşil kart açılmadı',
      tip: 'İşte bu kadar!Arkada yapamam~~',
      changeNickName: 'Takma adı değiştirme',
      confirm: 'Kurtarmak',
      nickName: 'Lütfen yeni bir takma ad girin',
      personal: 'Kişisel veriler başarıyla değiştirildi!',
      message: 'Lütfen bir takma ad girin',
      myBills: 'Kuponlarım',
      persent: 'Kullanmak için lütfen 520it girin',
      bill: 'Kupon',
      condition: 'Kullanım koşulu yok \n 1,5 yuana kadar indirim',
      rmb: 'Yuan',
      myOrders: 'Emirlerim',
      itemsTitle: ['tüm', 'Bekleyen ödeme', 'Alınacak', 'Değerlendirilecek'],
  
      personalInfo: 'Kişisel veri',
      head: 'avatar',
      user_name: 'lakap',
      userSex: 'cinsiyet',
      Brithday: 'doğum günü',
      phoneNumber: 'Cep telefonu numarası',
      noInput: 'Doldurulmama',
      logout: 'Oturumu aç',
      infoTip: 'Kişisel veriler başarıyla değiştirildi',
      loginInfo: 'Oturumunuza sonlarınıza emin misiniz?',
      infoSuccess: 'Başarılı olarak çık',
      year: 'yıl',
      month: 'ay',
      day: 'gün',
      man: 'Yakışıklı adam',
      woman: 'Belle',
  
      greenCard: 'yeşil kart',
      greenCardPower: 'yeşil kart ayrıcalık',
      fiftypecert: 'Yeşil kart açmak için %50 indirim',
      cardRecoamnd: 'Yeşil Kart Fişi Teklifinizi her gün alın',
      TodayExclusivecoupon: 'Bugünün Özel Kupon',
      everyUpadate: 'Her gün 0:00'+ `'` +'da güncellendi',
      to: 'tatmin etmek',
      toUse: 'Yuan kullanmak',
      immeatallyGet: 'Şimdi sahiplen',
      weekBill: 'Bu haftanın özel kupon',
      cardTip7: 'Şimdi sahiplen',
      billTip: 'Yeşil Kart Fişi Teklifinizi her gün alın',
      currentGoods: 'Şu anda alışveriş yapıyorsunuz',
      cardShop: 'Alışveriş yapmak için yeşil kart açma',
      cardBack: 'Döndürülen puanlar şunlardır',
      cardSpecialTip: 'Nokta hızlandırmayı şimdi aç',
      cardSpecial: 'Yeşil kartlar için özel fırsatlar',
      cardPriseb: '180Yuan',
      cardPrisea: '88Yuan',
      yearCard: 'Yıllık Geçiş',
      openCards: 'Yeşil kart açma',
      onece: '1 kez',
      twice: '2 kez'
    },
    // 订单
    order: {
      outTimeGetMoney: 'Puanlar 10 dakikadan fazla telafi edilebilir',
      inputForm: 'Sipariş formunu doldurma',
      location: 'Kargo adresini seçin',
      arrivalTime: 'Teslimat süresi',
      total: 'Toplam',
      thing: 'madde',
      wechatPay: 'WeChat Ödeme',
      aliPay: 'Alipay ödemesi',
      huabeiPay: 'Huabei ödemesi',
      use: 'kullanmak',
      order12: 'Puan kullanımı',
      mark: 'Açıklama',
      tip: 'Opsiyonel,Bize özel ihtiyaçlarınızdan bahsedebilirsiniz',
      goodsList: 'Mal listesi',
  
      totalMoney: 'Maddenin tutarı',
  
      sendMoney: 'Kargo ücretleri',
      point: 'İntegral',
      order19: 'Ödemeli',
  
      pay: 'Ödemeli',
      sendForm: 'Siparişinizi gönderin',
  
      selectArrivalTime: 'Teslimat süresi seçme',
      comfirm: 'Emin misiniz',
      goods: 'emtia',
      all: 'Toplam',
      things: 'madde',
      sigalPrice: 'birim fiyat:',
      numbers: 'miktar:',
      addTip: 'Henüz adres eklemedim,Bir tane ekle😄',
  
      addLocation: 'Adres ekleme',
      comfirmAndUse: 'Kaydetme ve kullanma',
      editLocation: 'Adresi düzenleme',
      myAddress: 'Adresim',
      settlement: 'kasaba',
      chooseGoods: 'Lütfen faturalanması gereken ürünü seçin',
      deliveryTime: 'Lütfen bir teslimat süresi seçin',
      today: 'Bugün',
      tomorrow: 'Yarın'
    },
    Login: {
      indexTitle: 'Oturum açma',
      Daftar: {
        title: 'Kayıt',
        phone: 'Lütfen cep telefonu numaranızı girin',
        code: 'Lütfen doğrulama kodunu girin',
        password: 'Lütfen bir parola girin',
        againPassword: 'Onaylamak için lütfen parolayı yeniden girin'
      },
      Forgot: {
        sendCode: 'Gönder',
        confirm: 'Gönder',
        goBack: 'dönmek'
      },
      nameInput: 'Lütfen cep telefonu numaranızı girin',
      pwdInput: 'Lütfen bir parola girin',
      remenber: 'Parolayı unutmayın',
      loginButton: 'Oturum açma',
      registrasi: 'Kayıt'
    },
    // 登录
    login: {
      title: 'Oturum açma',
      phoneNumber: 'Cep telefonu numarası',
      phoneNumberNotCorrect: 'Telefon numarası biçimi yanlış',
      phoneNumberNotEmpty: 'Telefon numarası boş olamaz',
      passNumberNotEmpty: 'Parola boş olamaz ',
      phoneInput: 'Lütfen telefon numaranızı girin',
      pass: 'parola',
      passTip: 'Lütfen bir parola girin',
      varify: 'Lütfen doğrulama kodunu girin',
      otherMethods: 'Oturum açmanın diğer yolları',
      wechat: 'WeChat girişi',
      qqchant: 'QQ oturumu açma',
      tipTile: 'Ipuç -ları：',
      tipContent: 'Kayıtsız telefon numarası ，Oturum açtığınızda kayıt otomatiktir，ve temsilci kabul eder',
      tip: 'Kullanıcı Sözleşmesi',
      tipProcy: 'gizlilik ilkesi',
      login: 'Oturum açma',
      resgin: 'Kayıt',
      passTip2: 'Lütfen bir parola girin(6 basamaktan az değil)',
      sendVerify: 'Doğrulama kodu gönderme',
      hasSend: 'Gönderilen',
      phoneVerify: 'Hesap parolası oturumu açma',
      smsMessage: 'SMS doğrulama kodu girişi',
      switchLoginMsg: 'Hesap parolası oturumu açma',
      messageSuccess: 'Doğrulama kodu başarıyla alındı,Lütfen giriş kutusuna girin',
      pleaseInputCorrectPhoneNumber: 'Lütfen doğru cep telefonu numarasını girin',
      pleaseInputCorrectVerifyumber: 'Lütfen doğru doğrulama kodunu girin',
      otherWechat: 'WeChat girişi-Henüz bitmedi',
      otherQQ: 'QQ oturumu açma-Henüz bitmedi',
      message: 'Doğrulama kodu:'
    },
    Hint: {
      notMore: 'Artık yok',
      loading: 'Yükleme',
      failed: 'Başarısız',
      SendCodeSuccess: 'Doğrulama kodu başarıyla gönderildi!',
      phoneNot: 'Cep telefonu numarası/hesap numarası boş olamaz',
      pwdInconformity: 'İki parola girdisi tutarsız',
      notInput: 'Giriş tamamlanmadı',
      commissionMoney: 'Return commission TRY:', // 返佣额度
    },
    newTab: {
      home: 'Ev',
      money: 'Şarj',
      order: 'Sipariş',
      my: 'O benim',
    },
    tab: {
      make_money: 'Sipariş alma'
    },
    Cart: {
      zzInfo: {
        buyIng: 'Devam ediyor',
        buyFailed: 'Satın alma başarısız oldu',
        notLevel: 'Üyelik seviyeniz yetersiz',
        buyJurisdiction1: 'Altın Üyelik siparişi satın almak için',
        buyJurisdiction2: 'Platinum üyelik siparişi satın almak için',
        buyJurisdiction3: 'Diamond üyelik siparişi satın almak için',
        invite1: '3 arkadaşı birlikte para kazanmak için davet edin',
        invite4: '10 arkadaşı birlikte para kazanmak için davet edin',
        invite3: '30 arkadaşı birlikte para kazanmak için davet edin',
        invite2: 'Arkadaşlarınızı davet etme',
        myMoney: 'Hesap bakiyeniz',
        under: 'Aşağıda',
        notSendMoney: 'Ticaret mümkün değildir',
        goTopUp: 'Lütfen Yukarı Yükleme' + `'` + 'ye gidin',
        know: 'Biliyorum',
        account: 'kasaba'
      },
      hint: {
        p1: '1. Farklı üyelik düzeylerinde farklı satınalma siparişleri vardır',
        p2: '2. Üyelik seviyesi ne kadar yüksekse, satın alınabilecek siparişlerde komisyon miktarı da o kadar yüksek olur',
        p3: '3. Satın alınan siparişler için siparişin durumunu istediğiniz zaman izleyebilirsiniz'
      },
      state: {
        all: 'tüm',
        going: 'Devam ediyor',
        paid: 'Ödenen',
        frozen: 'Donmuş',
        over: 'Yapılmış',
        canceled: 'İptal edilmiş'
      },
      info: {
        allMoney: 'Toplam varlıklarım TRY',
        dayAllOrder: 'Bugünün toplam siparişi',
        dayTrustMoney: 'Bugün emanet',
        yesterdayMoney: 'Dünün geliri'
      },
      text: {
        one: 'Sipariş'
      }
    },
    My: {
      download: 'APP' + `'` + 'i indirin',
      personal: 'Kişisel Bilgiler',
      recharge: 'Yükleme kayıtları',
      withdrawal: 'Para çekme',
      language: 'Dil ayarları',
      logout: 'oturumu kapatma',
      MemberInformation: {
        balance: 'Hesap Bakiyesi',
        Withdraw: 'Para çekme',
        Recharge: 'Şarj',
        forzenAll: 'Toplam tutarı dondurma'
      },
      PersonalInformation: {
        Page: {
          Avatar: 'avatar',
          Nickname: 'lakap',
          PhoneNumber: 'telefon numarası',
          BankAccounts: 'Banka kartları',
          Password: 'parola',
          title: 'Kişisel Bilgiler'
        },
        portrait: {
          SetAvatar: 'Avatar ayarları',
          button: 'yenilemek'
        },
        Name: {
          ModifyNickname: 'Takma adı değiştirme',
          title: 'Takma adı değiştirme',
          name: 'lakap',
          Please: 'Lütfen değiştirmek istediğiniz takma adı girin'
        },
        Password: {
          title: 'Oturum açma parolasını değiştirme',
          PleaseEnter: 'Lütfen değiştirmek istediğiniz parolayı girin',
          phone: 'Cep telefonu numarası',
          NewPhone: 'Lütfen cep telefonu numaranızı girin',
          verificationCode: 'Doğrulama kodu',
          verificationCodeNote: 'Lütfen doğrulama kodunu girin',
          ConfirmPassword: 'parola',
          ConfirmPasswordNote: 'Lütfen değiştirmek istediğiniz parolayı girin',
          Text: 'Şifreyi unutmayın, şifreyi unutursanız, lütfen müşteri hizmetleriyle iletişime geçin.',
          Button: 'Değişikliği onaylama'
        },
        AccountData: {
          title: 'Hareket ayrıntıları',
          To: 'varmak', // 到
          search: 'Aramak', // 搜索
          Cancel: 'İptal', // 取消
          Semua: 'tüm', // 全部
          Penarikan: 'cezbetmek', // 吸引力
          IsiUlang: 'Yeni -den yükle', // 重新加载
        },
         // 个人中心-充值记录
    //   RechargeRecord: {
    //     title: 'Recharge record', // 充值记录
    //     Recharge: 'Recharge offline', // 离线充值
    //     Online: 'Online recharge', // 在线充值
    //     // Amount: 'Recharge amount Rp:', // 充值金额Rp
    //     Amount: 'Recharge amount ZAR:', // 充值金额Rs
    //     Order: 'Order number:', // 订单号
    //     Status: 'Recharge status:', // 充值状态
    //   },
      }
    },
    newAdd: {
      warmTitle: 'sıcak istem',
      all: 'tüm',
      Completed: 'Yapılmış',
      unfinished: 'devam edilecek ',
      Income: 'gelir',
      Expenditure: 'Harcama',
      GetCodeBtn: 'Doğrulama kodunu alma',
      copySuccess: 'Çoğaltma başarılı oldu',
      copyError: 'Çoğaltma başarısız oldu',
      noLoginUserName: 'Lütfen giriş yapın',
      Withdraw: 'Para çekme',
      Recharge: 'Şarj',
      commission: 'komisyon',
      single: 'tek',
      missionHall: 'Görev salonu',
      videoTutorial: 'Video eğitimi',
      helpCenter: 'Yardım Merkezi',
      inviteFriends: 'Arkadaşlarınızı davet etme',
      help1Title: 'CG ile komisyon nasıl kazanılır',
      help1Context: 'E-ticaret patronunun mağazanın satışlarını eklemesi gerekiyor, bu yüzden sadece CG' + `'` + 'deki e-ticaret mağazasının gereksinimlerini tamamlamamız ve siparişi tamamlamamız gerekiyor. S ahipleri bize bir komisyon verecek. H er kullanıcı günde 10 sipariş görevi alabilir.  Bir günde % 10 ila% 30 komisyon kazanabilirsiniz',
      help2Title: 'Görev sayısı nasıl artırılır',
      help2Context: 'Çok sayıda yarı zamanlı çalışan nedeniyle, şirket tüm çevrimiçi yarı zamanlı çalışanların iletişim kurmak ve görevleri almak için benzersiz bir whatsapp kullanmasını şart koşar',
      help3Title: 'Görev nasıl tamamlanır',
      help3Context: 'Büyük satıcıların satışları artırmasına ve işlem sayısına göre sizi ödüllendirmelerine yardımcı olun.  Her başarılı sipariş için bir komisyon kazanırsınız ve satıcı bir itibar kazanır.',
      referralLink: 'Yönlendirme bağlantıları',
      copy: 'kopya etmek',
      blance: 'denge',
      todayOrder: 'Bugünün emri',
      provided: 'Bu veriler',
      Transaction: 'ticaret',
      Frozen: 'dondurmak',
      Done: 'bitirmek',
      Cancel: 'İptal',
      ExpectedReturn: 'Beklenen dönüş:',
      ImmediatePayment: 'Şimdi öde',
      AvailableNum: 'Kullanılabilir sayı',
      OrderImmediately: 'Şimdi sipariş ver',
      MakeMoneyEveryDay: 'Her gün para kazanın',
      TodayRecord: 'Bugünün Rekoru',
      AllocatCommissions: 'Komisyonları dağıtma',
      FrozenAmount: 'Dondurma tutarı',
      TaskMatch: 'Görev atamaları',
      AvailableBalance: 'Kullanılabilir bakiye',
      WaitDispatch: 'Görevin atanmasını bekleyin, lütfen mağaza yöneticisine başvurun!',
      OrderDescription: 'Bugünün Rekoru',
      // CouldMatch: '每个账户每天可配',
      CouldMatch: 'Görevler sistem tarafından rastgele eşleştirilir',
      OrderPerDay: 'tek.',
      DescriptionRate: 'Eşleştirme komisyonu sipariş tutarı olarak birleştirilmiştir',
      DescriptionPeriod: '.',
      Description3: 'Sistem, bulut üzerinden malları otomatik olarak eşleştiren LBS teknolojisine dayanmaktadır.',
      Description4: 'Platform izlemeyi önlemek için, sipariş onaylanırsa ve 20 dakika içinde gönderilirse, sistem sipariş miktarını dondurur.',
      Note: 'Önlem:',
      DescriptionNote: 'Platform kullanıcının siparişiyle eşleştiğinde, platform sipariş bilgilerini satıcının arka planına gönderir. Kullanıcı siparişi 20 dakika içinde göndermezse, \
      Sipariş tutarı sistem tarafından dondurulur. Denetim sisteminden kaçınmak için, kullanıcı hesabı puanlanacak ve bu da kullanıcının bir sonraki eşleşmesini doğrudan etkileyecektir\
      Ağızdan ağıza! İşlenmeleri gereken başka şeyler varsa, zaman aşımının donmasını önlemek için lütfen Sipariş Vermeyi Zamanında Durdur' + `'` + 'u tıklayın',
      OrderNum: 'Sipariş numarası:',
      TotalOrderPrice: 'Fiyat:',
      SubmitNow: 'Şimdi gönder',
      Record: 'Kayıt',
      MemberAccount: 'Üye Hesapları',
      AccountPlaceholder: 'Lütfen hesabınızı girin',
      RechargeAmount: 'Yükleme tutarı',
      RechargeAmountPlaceholder: 'Lütfen şarj tutarını girin',
      SelectRechargeMethod: 'Nasıl yükleme yapmak istediğinizi seçin',
      NotBeenSentOrder: 'Sipariş sevk edildi, lütfen siparişi göndermek için personelle iletişime geçin!',
      TodayOrderOver: 'Bugünün siparişi tamamlandı, lütfen yarın tekrar deneyin!',
      NotLogin: 'Henüz giriş yapmadınız, lütfen giriş yapın ve yeniden deneyin!',
      HaveNoteMethod: 'Şu anda böyle bir şarj yöntemi yok, lütfen başka bir şarj yöntemi seçin~',
      Login: 'Oturum açma',
      AccountRecord: 'Muhasebe kayıtları',
      RealName: 'Gerçek ad',
      IFSCCODE: 'IFSC',
      BankName: 'Banka kartının adı',
      BankNum: 'IBAN hesabı', 
      Mobile: 'cep telefonu',
      MobileNum: 'Cep telefonu numarası',
      Email: 'posta kutusu',
      EmailRequired: 'E-posta adresi gereklidir',
      AddBankCardNote: 'Banka kartları önemli bilgilerdir, lütfen dikkatlice doldurun!',
      Submit: 'Gönder',
      AddBankCardTitle: 'Kart ekleme',
      BankCard: 'Banka kartları',
      Name: 'ad',
      CardNum: 'Kart numarası',
      Reading: 'Okuma devam ediyor...',
      AllBalance: 'Toplam bakiye',
      EnterWithdrawalAmount: 'Lütfen para çekme tutarını girin',
      AllOut: 'tüm',
      AskNow: 'Para çekme',
      EnterPwdPlaceholder: 'Lütfen bir parola girin',
      NotHaveCard: 'Henüz bir banka kartı bağlamadın, banka kartı mı bağlayacaksın?',
      GetBankInfoFail: 'Banka bilgileri alınamadı',
      EnterValidPayPwd: 'Lütfen geçerli bir ödeme parolası girin',
      Balance: 'denge',
      Total: 'Toplam',
      total: 'Toplam',
      records: 'Kayıt',
      DisplayPerPage: 'Sayfa başına görüntülenir',
      NullNum: 'şerit',
      pages: 'sayfa.',
      toAudit: 'İnceleme bekleniyor',
      succeed: 'Başarılı',
      RechargeRecordTitle: 'Yükleme kayıtları',
      rests: 'başka',
      Arrive: 'Geldi',
      forzenCommission: 'Komisyonları dondurma',
      companyIntro: 'Şirket Profili',
      companyTitle: 'Tata Alışveriş Merkezi, Tata Group' + `'` + 'un bir parçasıdır',
      companyText: 'Tata Group, 1868 yılında kurulan ve merkezi Hindistan' + `'` + 'ın Mumbai kentinde bulunan Hindistan' + `'` + 'ın en büyük iş grubudur. şirket yedi iş alanında faaliyet göstermektedir: iletişim ve bilgi teknolojisi, mühendislik, malzeme, hizmetler, enerji, tüketim malları ve kimyasal ürünler. Hindistan' + `'` + 'da hızla büyüyen bir iş grubu olan Tata Group' + `'` + 'un altı kıtada 80' + `'` + 'den fazla ülkede 200' + `'` + 'den fazla iştiraki bulunuyor ve 85 ülkeye ürün ve hizmet ihraç ediyor. Tata Group' + `'` + 'un 2020-2021 mali yılı için toplam geliri 100,9 milyar ABD doları dır ve bunun %58' + `'` + 'i denizaşırı operasyonlardan elde edildi. Grup dünya çapında yaklaşık 450.000 kişiyi istihdam ediyor. Tata adı Hindistan' + `'` + 'da 153' + `'` + 'ten beri yaygın olarak saygı görüyor, çünkü iyi değerlere ve iş etiğine bağlı. Forbes' + `'` + 'un dünya çapındaki en saygın şirketleri arasında 11.',
      depositAddress: 'Yükleme adresi',
      rechargeSteps: 'Adımları şarj et',
      completePayment: 'Şarj işlemi tamamlandı',
      rechargeNow: 'Şimdi yükleme',
      rechargeCancel: 'Şarjı iptal et',
      bankName: 'Banka adı',
      payee: 'Alacaklı',
      cardNum: 'Hesap alınıyor',
      reviewing: 'İnceleniyor',
      remittance: 'Havaleler yapıldı',
      notThrough: 'Başarısız',
      OrderID: 'Sipariş Kimliği'
    },
    Financing:{
        RP: 'TRY', // 钱的单位(土耳其)
        assets: 'Toplam varlıklar TRY', // 总资产Rs
        amount: 'Finansal tutarlar TRY', // 财务金额Rs
    }
  }
  