<template>
  <div class="welfare_dialog_overlay" v-if="show" :style="{'--img': `url('${backgroundImage}')`}" @click="handleClickOverlay">
    <div class="content_wrap" @click.stop="()=> {}">
      <div class="title">{{ $t("welfareDialogTitle") }}</div>
      <div class="contents">{{ content }}</div>
      <div class="btn_wrap">
        <div class="btn" @click="read">{{ $t("Button.confirm") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { READ_NOTICE } from "@tt/api";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "",
    },
    msgId: {
      type: String | Number,
      default: "",
    },
    next: false,
    clickOverlay: {
      type: Boolean,
      default: true
    },
    backgroundImage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      welfareDialogContent: "",
    };
  },
  model: {
    prop: "show",
    event: "updateShow",
  },
  methods: {
    done() {
      this.$emit("updateShow", false);
    },
    handleClickOverlay() {
      if (this.clickOverlay) {
        this.$emit("updateShow", false);
      }
    },
    async read() {
      this.done();
      try {
        const { data: { next } } = await READ_NOTICE(this.msgId)
        this.$emit("read", this.next, next)
      } catch (error) {
        if(error.msg){

        }
      }
      // this.$api.readNotice(this.msgId, (res) => {
      //   const {
      //     ret,
      //     data: { next },
      //   } = res.data;
      //   let params = {};
      //   if (ret === 1) params = next;

      //   this.$emit("read", this.next, params);
      // });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.welfare_dialog_overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .content_wrap {
    padding: 122px 20px 74px;
    width: calc(90vw - 110px);
    height: calc(400px - 122px - 74px);
    // background-image: url("../assets/home/dialog_bg.png");
    background-image: var(--img);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .title {
      font-weight: 700;
      font-size: 22px;
      text-align: center;
      color: #963115;
      color: yellow;
      margin-top: 40px;
    }

    .contents {
      height: 320px;
      padding: 12px 14px;
      padding-right: 6px;
      word-break: keep-all;
      overflow: auto;
      // color: #371204;
      color: #fff;
      line-height: 2;
      display: flex;
      align-items: center;
      font-size: 18px;
      justify-content: center;
      // font-weight: bold;
    }

    .btn_wrap {
      position: absolute;
      width: 90vw;
      left: 50%;
      transform: translateX(-50%);
      bottom: 35px;
      display: flex;
      justify-content: center;

      .btn {
        line-height: 34px;
        // background: red;
        width: 200px;
        // margin-left: 10px;
        text-align: center;
        color: rgb(259, 30, 101);
        font-weight: 600;
      }
    }
  }
}
</style>