import verConfig from "./verConfig";

const formatMoney = (num, showUnit = true, options = {}) => {
  if(verConfig.homeProfitFormat){
    num = moneyFormat(parseInt(num));
  }
  if(verConfig.moneyFormatWithBrazil){
    num = formatWithBrazil(num);
  }
  if(!showUnit){
    return num
  }
  const type = options.moneyType || verConfig.moneyType;
  if (type == "₫") {
    return `${num}${type}`;
  } else if (type == "USDT") {
    return `${type} ${num} `;
  }
  return `${type}${num}`;
}

const moneyFormat = (money) => {
  if(isNaN(Number(money))) return 0;
  const arr = money.toString().split("").reverse();
  for (let i = 0, len = arr.length; i < Math.floor(len / 3); i++) {
    arr.splice(3 * (i + 1) + i, 0, ".")
  }
  if (arr[arr.length - 1] === ".") {
    arr.pop();
  }
  return arr.reverse().join("");
}

const formatWithBrazil = (money) => {
  if(isNaN(Number(money))) return 0;
  money = money.toString();
  const int = money.split(".")[0];
  const decimal = money.split(".")[1] || "00";
  return `${moneyFormat(int)},${decimal}`
}
export default formatMoney