import {$post, $get, domain} from '@tt/utils/src/request';
import {cookie} from '@tt/utils'
import {Toast} from "vant";

let loadNum = 0;

function setLoading() {
	if (!loadNum) {
		Toast.loading({
			duration: 0,
			forbidClick: true
		})
	}
	loadNum++;
}

function clearLoading() {
	if (loadNum > 0) {
		loadNum--;
	}
	if (loadNum < 1) {
		Toast.clear();
	}
}

function intercept(func) {
	return async function (...agrs) {
		setLoading();
		try {
			const {data: res} = await func(...agrs);
			const {ret} = res;
			clearLoading();
			if (ret == 1) {
				return Promise.resolve(res);
			}
			return Promise.resolve(res);
		} catch (error) {
			clearLoading();
			return Promise.reject(error)
		}
	}
}

/**
 * 获取地址列表
 * T7201
 */

export const GET_ADDRESS_LIST = (id = "") => intercept($post)('/users/address', {id: id});

/**
 * 添加修改地址
 * T7201
 */

export const ADD_ADDRESS = (params = {}) => intercept($post)('/users/address/add', params);

/**
 * 删除地址
 * T7201
 */

export const DELETE_ADDRESS = (id) => intercept($post)('/users/address/del', {id: id});


export const GET_PATNERPICS = () => $get('/content/slide', {position: 'product'}).then(res => {
	if (res.data.ret == 1) {
		return Promise.resolve(res.data)
	}
	return Promise.reject(res.data)
})

/**
 * 获取首页商品参数：
 * home_show=1    首页显示
 * id       可选：传值为获取详情
 * page_size    可选：单页数量
 * page      可选：默认第一页：1
 * asc=字段名      可选：顺序排，：price：价格    id：最新    task_index：人气   task_total_num：总需人次
 * desc=字段名    可选：倒序排
 * @param {*} data
 * @returns
 */
export const GET_PROS = (data) => intercept($get)('/task/product', data)

/**
 * 加入任务
 * id, amount
 * @param {*} data
 * @returns
 */
export const JOIN_TASK = (data) => intercept($post)('/task/joinTask', data)

/**
 * 支付任务订单
 * @param id
 * @returns {Promise<unknown>}
 * @constructor
 */
export const PAY_TASK_ORDER = (id) => intercept($post)("/task/payOrder", {id})

export const ORDER_RECEIVED = (id) => intercept($post)("/task/completeOrder", {id})

/**
 * 计算已参数数量
 * @param item
 * @return {number}
 */
export const MATH_PARTICIPATENUM = (item) => item.task_index + (item.task_pre_complete * 1) - item.task_cancel + (item.task_complete * 1);

export const READ_NOTICE = async (ids) => {
	try {
		const {data: {ret, data, msg}} = await $post("/users/message/reader", {ids});
		if (ret == 1) {
			return Promise.resolve({ret, msg, data})
		}
		return Promise.reject({ret, msg, data})
	} catch (error) {
		return Promise.reject(error);
	}
}

export const UNREAD_NUM = async () => {
	try {
		const {data: {ret, msg, data}} = await $post("/users/message/unread");
		if (ret == 1) {
			return Promise.resolve(data?.unread || 0);
		}
		return Promise.reject({ret, msg, data});
	} catch (error) {
		return Promise.reject(error);
	}
}

export const GET_MESSAGES = async (params) => {
	try {
		const {data: {ret, msg, data}} = await $get("/users/message", params);
		if (ret === 1) {
			return Promise.resolve({ret, msg, data})
		}
		return Promise.reject({ret, msg, data})
	} catch (error) {
		return Promise.reject(error);
	}
}

export const GET_UNREAD_MESSAGES = (params) => GET_MESSAGES({...params, status: "unread"})

export const GET_LIST_COM = (url, params) => new Promise(async (resolve, reject) => {
	try {
		const res = await $post(url, params, {}, cookie.get('token'))
		const {ret, msg, data} = res.data
		if (ret == 1) {
			if (!data) return reject('Hint.noData')
			resolve(data)
			return
		}
		reject(res.data)
	} catch (error) {
		if (error?.data?.msg) {
			reject(error.data)
		}
	}
})

// 登录
export const LOGIN = (params) =>
	$post('/users/login', params)

// 发验证码
export const SEND_SMS_CODE = (params) => {
	return $get('/users/sms', params)
}

// 余额宝
export const YUEBAO_INFO = () => {
	return $get('/yuebao/transfer/in',)
}

export const SET_GIFT = (params) => {
	return $get('/users/time/gift', params)
}

// 获取配置
export const GET_CONFIG_INFO = (params) => {
	return $get('/other/config', params)
}

// 获取银行卡信息
export const GET_USER_BANK_INFO = (params) => {
	return $get('/users/bank', params)
}

// 提交银行卡信息
export const COMMIT_USER_BANK_INFO = (params) => {
	return $post('/users/bank/add', params)
}

// 提现
export const WITHDRAW = (params) => {
	return $post('/users/extract', params)
}

// 设置/修改支付密码
export const SETTING_PAY_PDW = (params) => {
	return $post('/users/edit/paypass', params)
}

// 提现列表
export const GET_WITHDRAW_LISTS = (params) => {
	return $post('/users/extract/record', params)
}

// 获取分享配置
export const GET_SHARE_GIFT = () => {
	return $get('/users/share/gift')
}

// 充值
export const RECHARGE_COMMIT = (params) => {
	return $get('/pay/submit', params)
}

// 充值列表
export const GET_RECHARGE_LISTS = (params) => {
	return $post('/pay/record', params)
}

export const GET_USER_INFP = () => {
	return $post('/users/info')
}

// 查询用户信息
export const getUserInfoNew = (params) =>
	$post('/users/info', params)
		.then((r) => {
			if (cookie.get('token')) {
				if (r.data.ret === 1) {
					cookie.set('userInfo', JSON.stringify(r.data.data))
					cookie.set('token', r.data.data.token)
				}
			}
		}).catch((e) => {
		console.error(e)
	});

// 获取充值list
export const GET_RECHARGE_LIST = (params) => {
	return $post('/pay/lists', params)
}

// 账变list
export const GET_MONEY_LIST = (params) => {
	return $post('/users/account/lists', params)
}

export const Api = {
	baseUrl: domain + 'api/',
	/**
	 * 跳转客服
	 */
	toService() {
		// if (sessionStorage.getItem('service_url')) {
		//   window.location.href = cookie.get('service_url');
		// } else {
		this.getNotice(res => {
			// sessionStorage.setItem('service_url', res.service_url);
			window.location.href = res.service_url;
		})
		// }
	},
	/**
	 * 获取公告接口
	 * @param {*} success
	 * @param {*} fail
	 */
	getNotice(success, fail) {
		$get('/other/config', {act: 'system'}).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data);
				} else {
					if (fail) {
						fail(res.data.msg)
					}
				}
			}
		}).catch(err => {
			if (fail) {
				fail(err)
			}
		})
	},
	// 获取余额宝余额
	getFinancialBalance() {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await $get("/yuebao/balance", {}, {}, cookie.get("token"));
				const {ret, msg, data} = res.data;
				if (ret == 1) {
					resolve(data)
					return
				}
				reject(msg)
			} catch (error) {
			}
		})
	},
	/**
	 * 获取Banner图接口
	 * @param {*} success
	 * @param {*} fail
	 */
	getBanner(success, fail, position = "home") {
		// if (sessionStorage.getItem('bannerArr')) {
		//   success(JSON.parse(sessionStorage.getItem('bannerArr')))
		// } else {
		$get('/content/slide', {position}).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data);
					// sessionStorage.setItem('bannerArr', JSON.stringify(res.data.data));
				} else {
					if (fail) {
						fail(res.data.msg)
					}
				}
			}
		}).catch(err => {
			if (fail) {
				fail(err)
			}
		})
		// }
	},
	/**
	 * 获取等级配置接口
	 * @param {*} success
	 * @param {*} fail
	 */
	getLevel(success, fail) {
		// if (sessionStorage.getItem('levels')) {
		//   success(JSON.parse(sessionStorage.getItem('levels')))
		// } else {
		$post('/task/level', {}, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data);
					// sessionStorage.setItem('levels', JSON.stringify(res.data.data))
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			if (fail) {
				fail(err)
			}
		})
		// }
	},
	/**
	 * 获取用户信息接口
	 * @param {*} success
	 * @param {*} fail
	 * @param {*} wait
	 * @param {*} token
	 */
	getUserInfo(success, fail, wait, token) {
		if (cookie.get('userInfo')) {
			let userInfo = JSON.parse(cookie.get('userInfo'));
			$post('/users/info', {}, {}, token ? token : cookie.get('token')).then(res => {
				if (res.data.ret == 1) {
					if (success) {
						// userInfo.level = res.data.data.level;
						// userInfo.order_num = res.data.data.order_num;
						// userInfo.complete_order = res.data.data.complete_order;
						// userInfo.money = res.data.data.money;
						// userInfo.task_price = res.data.data.task_price;
						// userInfo.task_status = res.data.data.task_status;
						// userInfo.frozen_price = res.data.data.frozen_price;
						// userInfo.frozen_rate_price = res.data.data.frozen_rate_price;
						// userInfo.complete_total_price = res.data.data.complete_total_price;
						// userInfo.complete_yesterday_price = res.data.data.complete_yesterday_price;
						// userInfo.order_num = res.data.data.order_num;
						// userInfo.money_locked = res.data.data.money_locked
						// userInfo.userimg = res.data.data.userimg
						userInfo = {...userInfo, ...res.data.data}
						success(userInfo);
						cookie.set('userInfo', JSON.stringify(userInfo));
					}
				} else {
					if (fail) {
						fail(res.data.msg)
					}
				}
				return res;
			}).then((res) => {
				if (wait) {
					wait()
				}
			}).catch(err => {
				if (fail) {
					fail(err)
				}
			})
			// }
		} else {
			if (fail) {
				fail('Not logged in!');
				// fail();
			}
		}
	},


	getUserShareInfo(obj, fail, wait, token) {
		if (cookie.get('userInfo')) {
			let userInfo = JSON.parse(cookie.get('userInfo'));
			$post('/users/share/gift', obj, {}, token ? token : cookie.get('token')).then(res => {
				console.log(res.data)
				if (res.data.ret == 1) {
					console.log('success')
					localStorage.setItem('teamList', JSON.stringify(res.data))
					if (obj) {
						Toast('success')
						localStorage.setItem('teamList', JSON.stringify(res.data))
					}
				} else {
					if (fail) {
						fail(res.data.msg)
					}
				}
				return res;
			}).then((res) => {
				if (wait) {
					wait()
				}
			}).catch(err => {
				if (fail) {
					fail(err)
				}
			})
			// }
		} else {
			if (fail) {
				fail('Not logged in!');
				// fail();
			}
		}
	},
	/**
	 * 获取订单接口
	 * @param {*} data
	 * @param {*} success
	 * @param {*} fail
	 */
	getTask(data, success, fail) {
		$post('/task/getTask', data, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			if (fail) {
				fail(err)
			}
		})
	},
	/**
	 * 获取订单列表接口
	 * @param {*} data
	 * @param {*} success
	 * @param {*} fail
	 */
	getTaskOrder(data, success, fail) {
		if (!data) {
			data = new Object;
		}
		data.page_size = 10;
		$post('task/getTaskOrder', data, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			fail(err)
		})
	},
	/**
	 * 支付订单接口
	 * @param {*} id
	 * @param {*} success
	 * @param {*} fail
	 */
	payTaskOrder(id, success, fail) {
		$post('task/payTaskOrder', {id: id}, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data)
				}
			} else {
				if (fail) {
					fail(res.data)
				}
			}
		}).catch(err => {
			if (fail) {
				fail(err)
			}
		})
	},
	/**
	 * 修改用户资料接口
	 */
	editUserInfo(data, success, fail) {
		$post('users/edit', data, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data);
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			if (fail) {
				fail(res.data.msg)
			}
		})
	},
	/**
	 * 修改头像的接口
	 * @param {*} data
	 * @param {*} success
	 * @param {*} fail
	 */
	editAvatar(data, success, fail) {
		$post('users/edit/avatar', data, {}, cookie.get('token')).then(res => {
			console.log(res);
			Toast(res.data.msg)
		})
		// $post('users/edit/avatar',data,{},cookie.get('token'),{
		//   'Content-Type': 'multipart/form-data'
		// }).then(res => {
		//   if(res.data.ret == 1){
		//     if(success){
		//       success(res.data.data)
		//     }
		//   }else{
		//     if(fail){
		//       fail(res.data.msg);
		//     }
		//   }
		// }).catch(err => {
		//   if(fail){
		//     fail(err);
		//   }
		// })
	},
	/**
	 * 获取短信验证码接口
	 * @param {*} data
	 * @param {*} success
	 * @param {*} fail
	 */
	getSMS(data, success, fail) {
		$post('users/sms', data, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			fail(err)
		})
	},
	editPwd(data, success, fail) {
		$post('users/edit/userpass', data, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			fail(err)
		})
	},
	/**
	 * 获取首页菜单的参数
	 * @param {Function} success
	 * @param {Function} fail
	 */
	getHomeConfig(success, fail) {
		$post('content/sale', {position: 'home-item'}, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			fail(err)
		})
	},
	/**
	 * 获取首页菜单-帮助的参数
	 * @param {Function} success
	 * @param {Function} fail
	 */
	getHomeHelp(success, fail) {
		$post('content/sale', {position: 'home-help'}, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			fail(err)
		})
	},
	getWithdrawRate(success, fail) {
		$post('/users/extract/rate', {}, {}, cookie.get('token')).then(res => {
			if (res.data.ret == 1) {
				if (success) {
					success(res.data.data)
				}
			} else {
				if (fail) {
					fail(res.data.msg)
				}
			}
		}).catch(err => {
			fail(err)
		})
	}
};