import Cookies from 'js-cookie'
export default {
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.$eventBus.$emit('languageChanged', language);
      // Cookies.set("language", language);
    }
  }
}
