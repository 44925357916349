export default {
  signStr: '1001', // 渠道
  // tab按钮
  tab: {
    my: 'My',
    home: 'Home',
    record: 'Record',
    grab: 'Grab',
    financing: 'Financing',
  },
  newTab: {
    home: 'Beranda',
    money: 'Pembiayaan',
    shop: 'Pesanan',
    order: 'Mutasi',
    my: 'Saya',
  },
  // 公共的key
  common: {
    unrealized: 'Doing',
    all: 'All',
    distributionTip: '0 delivery 0 distribution fee',
    easeBack: 'Ease back',
    addCarSuccess: 'Join shopping successfully',
    deleteConfirm: 'Are you sure to delete the selected item ?',
  },
  My: {
    personal: 'Informasi pribadi', // 个人信息
    preOrde: 'Mutasi Pesanan', // 预订单记录
    account: 'Detail akun', // 帐户详细信息
    recharge: 'Mutasi Deposit', // 充值记录
    withdrawal: 'Mutasi penarikan', // 提款记录
    invite: 'Undang teman', // 邀请朋友
    announcement: 'Informasi', // 公告
    team: 'Laporan tim', // 团队
    quit: ' Keluar', // 退出
    logoutEvent: 'Konfirmasi untuk keluar?', // 确定退出
    language: 'Pengaturan bahasa', // 语言设置
    languageButton: {
      english: 'English', // 英语
      indonesian: 'Indonesian', // 印尼语
    },
    logout: 'log keluar', // 登出
    // 个人中心的值-小饶
    MemberInformation: {
      SilverMember: 'Anggota perak', // 银牌会员（这个没用了）
      Invitation: 'Kode undangan', // 邀请代码
      balance: 'Balas rekening', // 账户余额
      Recharge: 'Isi ulang', // 充值
    },
    // 个人中心-小饶 -Personal information
    PersonalInformation: {
      // 个人中心选项的
      Page: {
        title: 'Informasi saya', // 我的信息
        Avatar: 'Foto profil', // 头像
        Nickname: 'Nama panggilan', // 昵称
        PhoneNumber: 'Nomor Hp', // 手机号
        BankAccounts: 'Akun bank', // 银行账户
        Password: 'Kata sandi', // 密码
        TransactionPassword: 'Kata sandi transaksi', // 交易密码
        ReceivingAddress: 'Alamat Pengiriman', // 收货地址
      },
      // 个人中心-修改头像
      portrait: {
        SetAvatar: 'Atur foto Profil', // 设置头像
        button: 'Setel sekarang', // 立即设置
      },
      // 个人中心-修改用户名
      Name: {
        ModifyNickname: 'Ubah nama panggilan', // title
        title: 'Ubah nama panggilan Anda', // input上方的提示
        name: 'Nama panggilan', // label
        Modify: 'konfirmasi modifikasi', // 提交按钮
        Please: 'Silakan masukkan nama panggilan', // input的placeholder
      },
      // 个人中心-银行卡信息
      BankAccounts: {
        BankAccounts: 'Tambahkan kartu bank', // 添加银行卡页面title
        YourInformation: 'Identitas Anda', // 姓名上方的title
        RealName: 'Nama asli', // 真实姓名
        RealNameNote: 'Nama Tidak Valid, silakan Coba Lagi', // 真实姓名的placeholder
        PhoneNo: 'No hp', // 手机号
        PhoneNoNote: 'Silakan masukkan no hp', // 手机号placeholder
        YourBankCard: 'Pesan dari Bank', // 银行卡信息的title
        BankAccount: 'Nomor Rekening', // 银行卡号label
        BankAccountNote: 'Please set Bank Account', // 银行卡号placeholder
        BankName: 'Nama bank', // 银行name
        BankNameData: {
          option1: 'Choose account bank',
          option2: 'Bank Central Asia',
          option3: 'Bank Negara Indonesia',
          option4: 'Bank Rakyat Indonesia',
          option5: 'Bank Mandiri',
          // option6: 'Bank Negara Indonesia', // TO DO 他这个是重复的
        }, // 银行选项
        BankBranch: 'Cabang bank', // 银行支行label
        BankBranchNote:
          'Tidak ada cabang pembukaan akun yang dimasukkan,harap setel cabang bank', // 银行支行palaceholder
        MoreInformation: 'Informasi lebih lanjut', // whatsApp上方title
        WhatsApp: 'WhatsApp', // whatsApp label
        WhatsAppNote: 'Silakan setel ID WhatsApp', // whatsApp palaceholder
        Text:
          'Pengingat: Halo, untuk menjamin hak-hak Anda, harap ikat nomor kartu dan informasi bank yang benar. Jika akun tidak dapat diterima karena pengisian yang salah, platform ini tidak bertanggung jawab!', // 添加银行卡的提示
        Settings: 'Pengaturan', // 确定（提示）
      },
      // 个人中心-修改密码
      Password: {
        title: 'Ubah kata sandi', // title
        PleaseEnter: 'Silakan masukkan kata sandi baru Anda', // 输入框上方的title
        // LoginPassword:"Login password",
        // LoginPasswordNote:"Please enter the login password",
        phone: 'phone', // 手机
        NewPhone: 'Silakan masukkan nomor ponsel Anda', // 验证手机号的轻提示
        verificationCode: 'verification code', // 验证码
        verificationCodeNote: 'Silakan masukkan kode verifikasi', // 请输入验证码
        ConfirmPassword: 'sandi', // 密码
        ConfirmPasswordNote: 'masukkan sandi anda', // 输入密码
        Text:
          'Harap ingat kata sandi, jika Anda lupa kata sandi, silakan hubungi layanan pelanggan.', // 提示
        ButtonCode: 'mendapatkan kode verifikasi', // 获取code
        Button: 'OK untuk mengubah', // 按钮
        verifyPhone: 'Silakan masukkan nomor ponsel Anda', // 验证手机号的轻提示
        PhoneNew: 'Silakan konfirmasi bahwa Anda telah selesai', // 是否输入完毕的轻提示
      },
      // 个人中心-修改支付密码
      // 个人中心-修改用户地址
      Site: {
        title: 'Modifikasi alamat tanda terima', // 接收地址修改
        ReceivingIdentity: 'Menerima informasi identitas', // 接收身份信息
        ReceivingAddress: 'Informasi alamat pengiriman', // 接收地址信息
        DetailedAddress: 'Alamat lengkap', // 详细地址
        DetailedAddressNote:
          'Tidak ada alamat rincian yang di masukkan,harap setel', // 请输入详细地址
        Set: 'Tetapkan sebagai alamat umum', // 设为公用地址
        Button: 'Setuju', // 完成
        hint: 'Silakan konfirmasi bahwa Anda telah selesai', // 请确认您已完成
      },
      // 个人中心-账户信息
      AccountData: {
        title: 'Rincian transaksi', // 交易明细
        To: 'untuk', // 到
        search: 'Pencarian', // 搜索
        Cancel: 'Batalkan', // 取消
        Semua: 'Semua', // 全部
        Penarikan: 'Penarikan', // 吸引力
        IsiUlang: 'Isi ulang', // 重新加载
      },
      // 个人中心-充值记录
      RechargeRecord: {
        title: 'Memuat ulang rekaman', // 充值记录
        Recharge: 'Isi ulang offline', // 离线充值
        Online: 'Memuatkan ulang online', // 在线充值
        Amount: 'Angkat ulang Rp:', // 充值金额Rp
        Order: 'Nomor perintah:', // 订单号
        Status: 'Status muat ulang:', // 充值状态
      },
      // 个人中心-提款记录
      WithdrawalRecord: {
        title: 'Rekaman penarikan', // 提款记录
        withdrawal: 'Tarik kartu bank', // 银行卡取款
      },
      // 个人中心-邀请好友
      InviteFriends: {
        Invite: 'Undang link', // 邀请链接：单击“复制”
        InvitationCode: 'Kode undangan:', // 邀请代码
        Click: 'Salin link', // 复制链接
      },
      // 个人中心-系统公告
      Announcement: {
        title: 'Pengumuman', // 公告
        System: 'Pengumuman sistem', // 系统公告
      },
      // 个人中心-团队报告
      TheTeamReport: {
        title: 'Laporan tim', // 团队报告
        To: 'Ke', // 到
        search: 'Cari', // 搜索
        Cancel: 'Batalkan', // 取消
        balance: 'Tim keseimbangan Rp', // 团队收益
        turnover: 'Pemukaran tim Rp', // 团队
        recharge: 'Total timnya memuat ulang Rp', // 团队总收益
        withdrawal: 'Total penarikan tim Rp', // 团队提现收益
        commission: 'Komisi pesanan tim Rp', // 团队佣金
        charge: 'Jumlah muatan pertama', // 首充次数
        people: 'Jumlah langsung orang', // 直接人数
        members: 'Jumlah anggota tim', // 团队成员数量
        NewPeople: 'Orang baru', // 新增人数
        Active: 'Nomor aktif', // 活跃人数
        Level1: 'Tingkat 1', // 等级1
        Level2: 'Tingkat 2', // 等级3
        Level3: 'Tingkat 3', // 等级2
      },
    },
    // 首页跳转下的四个静态页面 - 小饶
    PlatformIntroduction: {
      PlatformIntroduction: 'Pengenalan platform',
      // One:
      //   'Indonesia Alliance Mall Co., Ltd. didirikan pada tahun 2012 ，adalah sistem layanan cloud e-niaga yang berfokus pada manajemen proses penjualan untuk membantu perusahaan dengan cepat meningkatkan peningkatan penjualan, rekomendasi petunjuk akurat dipotong, dan mengintegrasikan saluran pemasaran dengan manajemen penjualan dari keseluruhan proses.',
      Two:
        'Platform ini merupakan aliansi yang terdiri dari jutaan pedagang dari Tokopedia, Shopee, Bukalapak, dan Lazada, Prinsip layanannya adalah: pedagang platform meningkatkan volume transaksi, dan anggota platform memperoleh pendapatan komisi dengan mengambil pesanan.',
      Three:
        'Membantu perusahaan mendapatkan pelanggan yang lebih efektif melalui mesin rekomendasi petunjuk pelanggan' +
        '"Cloud guest"' +
        'adalah platform pesanan pencocokan host kontrol cloud cerdas pihak ketiga.Platform ini bekerja dengan Tokopedia, Shopee, Bukalapak, Lazada dan pedagang platform belanja online lainnya, melalui sistem cloud cerdas untuk mencocokkan pesanan bisnis dengan pengguna platform, sehingga dapat meningkatkan volume perdagangan dan reputasi penjual di platform utama. mode pengiriman pesanan pembelian ditingkatkan, dan pesanan dikirimkan secara otomatis oleh server cloud cerdas jarak jauh.Pengguna platform hanya perlu memberikan alamat penerima yang yang lengkap , nomor telepon dan beberapa informasi belanja secara langsung di platform ， dan masuk ke host cloud cerdas. komputer Robot Cerdas dari jarak jauh akan kontrol cloud secara otomatis menganalisa proses operasi pesanan secara manual, membuat pencocokan pesanan online lebih efisien dan sederhana！',
      Four:
        'Setiap hari, platform akan menempatkan produk pelanggan yang perlu diekspos ke publik di platform ， dan menyediakan mereka ke platform untuk perampasan pesanan.Untuk menghindari deteksi platform belanja online dan meningkatkan keaslian pesanan , pengguna baru harus meningkatkan informasi pribadi mereka yang sebenarnya dan mengisi alamat penerima yang lengkap sebelum mengambil pesanan, sehingga penjual dapat meningkatkan kedapatan dan penjualan sementara tidak dianggap sebagai pesanan palsu oleh platform belanja online dan dengan demikian mempromosikan perkembangan bisnis yang sehat di platform utama. Untuk membantu penjual secara efektif meningkatkan tingkat keberhasilan pesanan dan menghindari risiko penjual diperiksa untuk pesanan palsu ， platform akan mengoptimalkan pencocokan pesanan untuk hari itu sesuai dengan IP operasi Anda saat ini dan jenis peralatan. Oleh karena itu, pencocokan pesanan perlu diwujudkan melalui algoritma komputasi awan yang cerdas. Mohon tunggu dengan sabar.Pengguna platform secara otomatis akan menjadi agen platform melalui promosi orang baru untuk bergabung dengan platform dan menjadi agen bisa mendapatkan komisi tambahan. ',
      Five:
        'Anda hanya perlu menggunakan waktu luang Anda untuk online, dan Anda dapat dengan mudah memperoleh pendapatan komisi yang besar setiap hari. Pokok dan komisi akan dikembalikan segera setelah pesanan berhasil diklik dan berhasil dikirim.',
    },
    RuleDescription: {
      RuleDescription: 'Pengaturan', // title
      One:
        '1.  Anggota harus mengisi alamat penerima dan meningkatkan informasi pribadi sebelum mengambil pesanan. Komisi anggota platform berbeda sesuai dengan tugas yang berbeda .  Jumlah pesanan yang dihasilkan oleh sistem berdasarkan 30% -70% dari saldo rekening anda. ', //
      Two:
        '2.  Karena pesanan barang memerlukan waktu tertentu untuk berlaku, pesanan hanya dapat diterima dengan penjual setelah pesanan berlaku, oleh karena itu penyelesaian komisi platform dan bank tripartit dan anggota menggunakan sistem T + 1. Yaitu: hari ini untuk menarik dan uang akan diterima pada keesokan hari sebelum 23:59. Jika pesanan gagal berlaku setelah waktu yang ditentukan, platform akan membayar di uangmuka, menyelesaikan akun dan mengeluarkan pembayaran dalam waktu yang ditentukan.', //
      Three:
        '3.  Kondisi aula yang sesuai dan rasio komisi    Anggota perak: Semua anggota Silver dapat mengambil 10 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,30% dari jumlah barang dagangan.', //
      Four:
        'Anggota emas: Untuk membuka anggota emas, Anda perlu mengundang hingga 3 orang untuk masuk ke aula ini. Anda dapat mengambil 35 pesanan per hari, dan komisi untuk pesanan adalah 0,35% dari jumlah barang dagangan.', //
      Five:
        'Anggota platinum: Untuk membuka anggota platinum, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.', //
      Six:
        'Anggota platinum: Untuk membuka anggota platinum, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.', //
      Seven:
        'Anggota berlian: Untuk membuka anggota Diamond, Anda perlu mengundang 30 orang untuk memasuki aula ini. Anda dapat memperoleh 45 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,45% dari jumlah barang dagangan.', //
      Eight:
        '4.  Harap periksa nomor kartu deposit platform sebelum mengisi dan platform akan mengubah nomor akun secara tidak teratur. Platform tidak akan bertanggung jawab atas kerugian properti yang disebabkan oleh kesalahan operasional pribadi.', //
      Nine:
        'Catatan: Silakan periksa informasi kartu bank dari platform apakah itu platform ini untuk setiap transfer untuk menghindari kerugian ekonomi Anda.', //
      Ten:
        '5.  Untuk melindungi profit antara penjual dan platform, oleh karena itu setiap ip, nomor kartu bank dan nomor ponsel hanya dapat berlaku untuk satu akun. Semua platform e-niaga utama memiliki mesin kontrol risiko, Jika banyak pengguna di area yang sama dan ip yang sama yang berbelanja di toko yang sama, akan menyebabkan produk bahan peledak toko dihilangkan dan mempengaruhi kredibilitas dan penjualan penjual tidak valid.', //
      Eleven:
        'Jika ditemukan seseorang mengajukan permohonan untuk beberapa akun, pelanggar akan membekukan akun secara permanen dan membekukan saldo selama 30 hari. Saat Anda perlu menarik saldo yang dibekukan (akhir periode berakhir), silakan hubungi layanan pelanggan online platform kami untuk mengaktifkan akun untuk ditarik.', //
      OneData: {
        One: 'Pengenalan tugas:',
        Two:
          'Jenis tugas\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Tarif komisi',
        Three:
          'Lazada\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.30%',
        Four:
          'Bukalapak\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.35%',
        Five:
          'Shopee\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.40%',
        Six:
          'Tokopedia\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.45%',
      },
      TwoData: {
        One: 'Deskripsi tingkat keanggotaan:',
        Two:
          'Jenis keanggotaan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Anggota perak',
        Three:
          'Batas penarikan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Four:
          'Jumlah pesanan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa030/hari',
        Five:
          'Tingkat komisi\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.30%',
        Six:
          'Undang\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0-',
      },
      FourData: {
        One:
          'Jenis keanggotaan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Anggota platinum',
        Two:
          'Batas penarikan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Three: 'Jumlah pesanan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa040/hari',
        Four:
          'Tingkat komisi\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.40%',
        Five:
          'Undang\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa010',
      },
      FiveData: {
        One: 'Jenis keanggotaan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Anggota berlian',
        Two:
          'Batas penarikan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Three:
          'Jumlah pesanan\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa045/hari',
        Four:
          'Tingkat komisi\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.45%',
        Five:
          'Undang\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa030',
      },
      Twelve:
        '*Level yang berbeda dapat memperoleh angka ganjil dan rasio komisi. Semakin tinggi level keanggotaan, semakin banyak angka ganjil yang bisa Anda ambil. Semakin besar urutannya, semakin tinggi komisi.', //
    },
    AgentCooperation: {
      AgentCooperation: 'Kerjasama agen', // title
      One: 'Peluncuran Standar Tingkat Ketiga untuk Proxy', //问1
      Two:
        'Anggota dapat menjadi proxy platform dengan merekomendasikan anggota baru yang bergabung untuk mendapatkan hadiah tambahan.', //
      Three:
        'Promosikan langsung hadiah pengguna tingkat pertama untuk mendapatkan komisi bonus 9% setiap hari .Untuk hadiah pengguna tingkat kedua adalah komisi bonus 6% setiap hari Untuk hadiah pengguna tingkat ketiga adalah komisi bonus 3% setiap hari.', //
      Four: 'Sebagai contoh:', //
      Five:
        'Proksi tingkat pertama Anda telah mengundang 10 orang untuk bergabung menjadi anggota, proxy tingkat kedua mengundang 20 orang untuk bergabung menjadi anggota dan proxy tingkat ketiga telah mengundang 40 orang untuk bergabung menjadi anggota. Pendapatan per orang untuk sehari adalah 200.000 IDR, jadi bayangkan saja penghasilan harian yang akan Anda dapatkan.', //
      Six: 'Level pertama: 200.000 x 10 x 9%  = 180.000 IDR', //
      Seven: 'Tingkat kedua: 200.000 x 20 x 6%  = 240.000 IDR', //
      Eight: 'Tingkat ketiga: 200.000 x 40 x 3%  = 240.000 IDR', //
      Nine:
        'Level pertama  +  Tingkat kedua  +  Tingkat ketiga  =  660.000 IDR', //
      Ten:
        'Misalkan Anda mengundang anggota A untuk mendaftar, A mengundang B, dan B mengundang C,', //
      Eleven:
        'Kemudian anggota A adalah pengguna tingkat pertama tim Anda, B adalah pengguna tingkat kedua Anda, dan C adalah pengguna tingkat ketiga Anda.', //
      Twelve:
        'Jumlah hadiah = komisi pesanan A x 9% + komisi pesanan B x 6% + komisi pesanan C x 3%.', //
      Thirteen:
        '*Pengingat: Hadiah komisi untuk semua agen yang merupakan anggota offline dikeluarkan oleh platform ini, dan tidak akan memengaruhi komisi semua agen dan anggota offline!', //
    },
    CommonProblem: {
      CommonProblem: 'Masalah umum', //title
      One: 'Q:Mengapa bisnis membutuhkan pesanan virtual?', // 问1
      Two:
        'Menurut aturan platform e-commerce utama, semakin banyak orang membeli produk, semakin populer produk tersebut, dan semakin banyak peluang akan didorong dan ditampilkan oleh platform e-commerce. Bisnis e-commerce sedang sengit. Untuk meningkatkan eksposur produk, pedagang perlu meningkatkan volume transaksi komoditas, sehingga mereka memilih untuk menghasilkan pesanan virtual di platform.', //答1
      Three: 'Q:Bagaimana cara menyelesaikan proses pengambilan pesanan?', //问2
      Four:
        'Pilih saluran e-niaga, klik untuk mengambil pesanan secara otomatis, sistem dengan cerdas mencocokkan produk untuk mendapatkan pesanan. Platform menggunakan informasi pribadi Anda yang sebenarnya, alamat pengiriman, dan IP jaringan untuk mengirimkan pesanan ke platform e-commerce untuk mensimulasikan belanja online. Namun, pedagang tidak benar-benar mengirimkan barangnya. Setelah pesanan berhasil dikirim, pokok dan komisi pesanan akan segera dikembalikan ke akun Anda.', //答2
      Five: 'Q:Mengapa saya perlu mengisi ulang sebelum menerima pesanan?', //问3
      Six:
        'Untuk memastikan keaslian pesanan, setiap platform pemesanan yang disimulasikan memerlukan pembayaran nyata ke situs web e-niaga. Untuk melindungi kepentingan platform dan pedagang, saldo akun Anda akan dikurangi saat pesanan dikirim, dan akan segera dikembalikan setelah pesanan selesai.', // 答3
      Seven:
        'Q:Bagaimana cara meningkatkan jumlah pesanan ambil dan meningkatkan komisi?', //问4
      Eight:
        'Dengan mengundang teman untuk mendaftar, semakin tinggi levelnya, semakin banyak jumlah total pesanan per hari, dan semakin tinggi rasio komisi;Sistem secara otomatis mencocokkan jumlah pesanan antara 30% dan 70% dari saldo akun, oleh karena itu, semakin besar saldo akun, semakin tinggi jumlah setiap pesanan dan semakin tinggi komisi.', // 答4
      Nine: 'Q:Bagaimana cara mengundang teman untuk mendaftar?', //问5
      Ten:
        'Klik "Undang Teman", salin tautan berbagi atau kode QR, dan kirimkan ke teman, teman sekelas, kolega, dan keluarga Anda melalui perangkat lunak sosial. Anda harus mengisi kode undangan untuk pendaftaran di platform ini, dan pihak lain akan menjadi anggota tim Anda setelah pendaftaran.', // 答5
      Eleven: 'Q:Bagaimana cara menarik uang tunai?', //问6
      Twelve:
        'Anda dapat mengajukan penarikan tunai setelah memenuhi kuantitas minimum order. Jika syarat kuantitas minimum order tidak terpenuhi, lanjutkan saja hingga persyaratan terpenuhi. Setelah penarikan disetujui, platform akan mendepositkan dana ke rekening bank terikat Anda, dan waktu penerimaan yang tepat tergantung pada bank Anda.', // 答6
    },
    // 首页会员升级页面 - 小饶
    MemberUpgrade: {
      MemberUpgrade: 'Peningkatan member', // title
      Member: 'Aras anggota：', //会员等级
      Orders: 'Perintah tersedia setiap hari: ', // 可用订单
      Commission: 'Bonus komisi', // 佣金奖金
      DedicatedOne: 'Layanan pelanggan tertentu', // 特殊客服服务
      DedicatedTwo: 'Layanan pelanggan tertentu', // 特殊客服服务
      Swipe: 'Geser untuk melihat lebih banyak', // 说明
      One: {
        Silver: 'Anggota perak', // 银卡会员
        Need: 'Undang 0 orang', // 邀请
        Withdrawal: 'Batas penanrikan:50.000.000', // 提款限额
        quantity: 'Jumlah pesanan:10/hari', // 订单数量
        rate: 'Tingkat komisi:0.30%', // 佣金率
        fee: 'Biaya penarikan:2.00%', // 提现费
        Activated: 'Tidak terkunci', //已解锁
      },
      Two: {
        Silver: 'Anggota emas', // 银卡会员
        Need: 'Undang 3 orang', // 邀请
        People: '3 orang masih pendek', // 缺人
        Withdrawal: 'Batas penanrikan:50.000.000', // 提款限额
        quantity: 'Jumlah pesanan:35/day', // 订单数量
        rate: 'Tingkat komisi:0.35%', // 佣金率
        fee: 'WBiaya penarikan:2.00%', // 提现费
        Activated: 'Jumlah isi ulang harian:1.000.000', //每日提款补充
        Invite: 'Segera mengundang', // 立即邀请
      },
      Three: {
        Silver: 'AnggotaPlatinum', // 白金会员
        Need: 'Undang 10 orang', // 邀请
        People: '10 orang masih pendek', // 缺人
        Withdrawal: 'Batas penanrikan:50.000.000', // 提款限额
        quantity: 'Jumlah pesanan:40/day', // 订单数量
        rate: 'Tingkat komisi:0.40%', // 佣金率
        fee: 'WBiaya penarikan:2.00%', // 提现费
        Activated: 'Jumlah isi ulang harian:1.500.000', //每日提款补充
        Invite: 'Segera mengundang', // 立即邀请
      },
      Four: {
        Silver: 'Anggota berlian', // 钻石会员
        Need: 'Undang 30 orang', // 邀请
        People: '30 orang masih pendek', // 缺人
        Withdrawal: 'Batas penanrikan:50.000.000', // 提款限额
        quantity: 'Jumlah pesanan:45/day', // 订单数量
        rate: 'Tingkat komisi:0.45%', // 佣金率
        fee: 'WBiaya penarikan:2.00%', // 提现费
        Activated: 'Jumlah isi ulang harian:2.000.000', //每日提款补充
        Invite: 'Segera mengundang', // 立即邀请
      },
    },
    PhoneNew: 'Silakan konfirmasi bahwa Anda telah selesai', // 是否输入完毕的轻提示
    // 轻提示 - 小饶
    Toast: {
      One: 'Simpan dengan sukses', //保存成功
      Two: 'Tidak berhasil', //保存失败
    },
    // 确定和取消的多文本 -- 小饶
    Button: {
      One: 'Batalkan', //取消
      Two: 'Setuju', //确定
    },
    logout: 'Keluar', // 个人中心下的退出按钮-小饶
  },
  Record: {},
  Grab: {},
  Financing: {
    time: 'Waktu', // 时间
    day: 'Hari', // 天
    Fina: 'Pembiayaan', // 标题
    assets: 'Total aset Rp', // 总资产RP
    amount: 'Jumlah pembiayaan Rp', // 财务金额RP
    revenue: 'Pendapatan total', // 总收入
    earnings: 'Penghasilan kemarin', // 昨日总收入
    Balance: 'Transfer saldo', // 余额转入
    RP: 'Rp', // 钱的单位
    Please: 'Harap masukkan jumlah transfer', // 金额输入框placeholder
    Estimated: 'Perkiraan pendapatan', // 预计收入文字
    Income: 'Standar Pendapatan', // 收入标准文字
    TransferOut: 'Transfer keluar', // 转出btn文字
    ransferIn: 'Transfer masuk', // 转入btn文字
    SukuBung: 'Suku bunga', // 商品名称
    Transfer: 'Transfer dalam gagal', //转入提示
    Pleaseint: 'Silakan masukkan jumlah', // 提示
    Successful: 'Successful transfer',
    Please: 'Please input the transfer in amount', // 请输入转入金额
    RansferInSU: {
      FinancialAcc: 'Financial Access Record',
      Acc: 'Access log',
    },
    // 首页客服页面
    ServiceSu: {
      Please: 'Silakan masukkan...', //请输入
      send: 'kirim keluar', //发送
      Online: 'Layanan Online', //在线客服
      Service: 'Layanan', //title
      Customer: 'Pelayanan pelanggan', //客服服务
      Ifyou: 'Jika ada kendala, silakan menghubungi layanan online service', // 服务说明
      Layanana8: 'Layanana Pelanggan No.8 (WhatsApp)', //服务号
      Layanana9: 'Layanana Pelanggan No.9 (WhatsApp)', //服务号
      Dedicated: 'Layanan pelanggan khusus', //专属客服
      Telegram8: 'Layanana Pelanggan No.8 (Telegram)', //服务号
      Telegram008: 'Telegram008', //服务号
      Telegram9: 'Layanana Pelanggan No.9 (Telegram)', //服务号
      Telegram009: 'Telegram009', //服务号
    },
  }, // 不确定
  Home: {
    HallofFame: 'Hall of Fame',
    Member: 'Berita Anggota', // 新闻
    Mission: 'Aula misi', // 宣讲堂
    Learnde: 'MemaHami', // 信息
    Hello: 'Hello', // 你好
    Let: 'Biarkan Alliance Mall Mutasi kekayaan', //说明
    total: 'Total aset saya', //我的总资产
    Withdraw: 'Penarikan', //收回
    Recharge: 'Deposit', //充值
    RP: 'Rp', //Rp
    earningsRp: 'Omset kemarin Rp', //昨天的收益
    incomeRp: 'Total Pendapatan Rp', //累计收益
    rarningsRp: 'Komisi hari ini Rp', //今日收益
    Service: 'Layanan', //服务
    Invite: 'Undang Teman', // 邀请
    Lazada: 'Lazada', // 会员白银
    Bukalapak: 'Bukalapak', // 会员黄金
    Shopee: 'Shopee', // 会员白金
    Tokopedia: 'Tokopedia', //会员钻石
    silver: 'Anggota perak', //白银说明
    gold: 'Anggota emas', //黄金说明
    platinum: 'Anggota platinum', // 白金说明
    diamond: 'Anggota berlian', //钻石说明
    Agent: 'Komisi pesanan agensi', // 滚动信息
    Order: 'Komissi pesanan', // 滚动信息
    // 首页充值页面
    RechargeSu: {
      Recharge: 'Top-up', //充值
      Catatan: 'Catatan khusus:', //充值说明
      Next: 'Berikutnya', //下一步
      RechargeAm: 'Jumlah Top-up', //充值金额
      Enter: 'Masukkan jumlah Top-up', //输入充值金额
      Quick: 'Pemilihan cepat', // 快速选着
      nameP: 'Silakan masukkan nama asli Anda', //请输入姓名
      name: 'Nama', //姓名
      Pleaseamount: 'Silahkan masukkan saldo', //请输入金额
      Pleaseint: 'Silakan masukkan jumlah', //只能输入0到9之间的整数：
      Pleasename: 'Silahkan masukkan nama Anda', //请输入姓名
    },
    // 首页子页面-Withdraw
    WithdrawSu: {
      Withdraw: 'Penarikan tunai', // title
      Withdrawal: 'Jumlah penarikan', // 提现金额
      WithdrawalRe: 'Tingkat penarikan', // 提现率
      Input: 'Masukkan jumlah penarikan', // 输入提款金额
      Balance: 'Saldo Akun', // 金额
      WithdrawAll: 'Tarik semua', //  全部选中
      Mobile: 'Nomor hp', // 银行卡号
      Bank: 'Nomor kartu bank', // 银行卡号
      Owned: 'Bank milik', // 所属银行
      Account: 'Nama akun', // 用户名
      Transaction: 'Kata sandi transaksi', // 密码
      Withdrawim: 'Tarik segera', // 提现按钮
      Pleaseamount: 'Silahkan masukkan saldo', //请输入金额
      Pleasephone: 'Silahkan masukkan Nomor HP Anda', //请输入您的手机号
      Pleasecard: 'Silahkan masukkan Nomor kartu bank Anda', //请输入银行卡号
      Pleasebank: 'Silahkan masukkan nama Bank Anda', //请输入您的银行
      Pleasename: 'Silahkan masukkan nama Anda', //请输入姓名
      Pleasepassword: 'Silahkan masukkan password transaksi', //请输入交易密码
      Pleaseint: 'Silakan masukkan jumlah', //只能输入0到9之间的整数：
      PasswordError: 'Password salah', //密码错误
      Successful: 'Telah mengajukan penarikan', //已经申请提款
      Insufficient: 'Penarikan saldo tidak cukup', //提现余额不足
      Unbound: 'Belum link ke kartu bank', //银行卡还没有绑定
      Binding: 'Link ke kartu bank', //绑定银行卡
      idCard: 'Kartu ID No', // 身份证
    },
  },
  Login: {
    versions: 'V.86', // 版本号
    masuk: 'Masuk', // 标签
    name: 'Unduh aplikasi', // 标签旁边的名称
    forgot: 'Forgot', // 找回密码文字
    registrasi: 'registrasi', // 注册账号文字
    loginButton: 'Masuk', // 登录的btn
    nameInput: 'masukkan nomor telepon anda', // 登录时账号input提示
    pwdInput: 'masukkan kata sandi anda', // 登录时密码input提示
    notLoginHint: 'Masuk', // 返回登录弹窗提示
    goLoginText: 'menentukan', // 返回登录弹窗去登陆btn
    Forgot: {
      title: 'Ambil kembali kata sandi',
      phone: 'Masukkan nomor ponsel', // 账号提示
      code: 'Masukkan kode verifikasi', // 验证码提示
      area: 'Please enter area code', // 暂时未使用
      sendCode: 'Kirim kode', // 发送短信btn文字
      password: 'Kata sandi', // 第一次密码提示
      againPassword: 'Konfirmasi kata sandi', // 再次输入密码提示
      confirm: 'Kirim', // 提交
      goBack: 'Punya akun, Masuk sekarang', // 返回
      register: 'Daftar', // 暂时未使用
    },
    Daftar: {
      title: 'Daftar',
      area: 'Please enter area code',
      phone: 'Nomor ponsel',
      code: 'Masukkan kode verifikasi',
      userName: 'nama panggilan',
      password: 'Kata sandi',
      againPassword: 'Konfirmasi kata sandi',
      referrer: 'Kode rekomendasi',
    },
  },
  Cart: {
    title: 'Toko pesanan', // 订单商城
    explain: 'Penjelasan', // 说明
    grade: {
      SliverMember: 'Anggota\nPerak', // 白银
      GoldMember: 'Gold\nMember', // 黄金
      PlatinumMember: 'Anggota\nPlatinum', // 白金
      DiamondMember: 'Diamond\nMember', // 钻石
    },
    info: {
      allMoney: 'Total Aset\n saya Rp', // 我的总资产Rp
      dayAllOrder: 'Total pesanan\n hari ini', // 今天的总订单
      dayTrustMoney: 'Komisi\n hari ini', // 今天委托金
      yesterdayMoney: 'Penghasilan\n kemarin', // 昨天的收入
    },
    text: {
      rebateAndPrincipal: 'Mengembalikan modal dan komisi', // 返佣及本金
      residue: 'Sisa', // 剩余
      one: 'Pesanan', // 单
      rebate: 'Pengembalian komisi', // 返佣
      // buyBtn: 'Segera memesan', // 立即抢单（换文字）
      buyBtn: 'Langsung beli', // 立即抢单
      toGet: 'Akan mendapat', // 即将获得
      orderSuccess: 'Pesanan\n berhasil', // 抢单成功
      orderGetMoney: 'Pembayaran\n Berhasil', // 付款成功
      orderWaiting: 'Sedang\n menunggu', // 等待中
      orderOver: 'Selesai', // 完成
    },
    state: {
      all: 'Semua', // 全部
      going: 'Dalam proses', // 进行中
      over: 'Telah selesai', //已完成
    },
    zzInfo: {
      buyIng: 'Pembelian', // 购买中
      buySucceedFindDT:
        'Setelah pembelian berhasil silahkan klik untuk lihat status pesanan', // 购买成功点击动态查看即可
      ok: 'Baik', // 好的
      buyFailed: 'Pembelian gagal', // 购买失败
      notLevel: 'Level VIP Anda tidak mencukupi', // 您的会员等级不足
      buyJurisdiction1: 'If want purchase a gold membership orde', // 如需购买黄金会员订单
      buyJurisdiction2: 'If want purchase a platinum membership order', // 如需购买白金会员订单
      buyJurisdiction3: 'If want purchase a diamond membership order', // 如需购买钻石会员订单
      meet: 'harus menyelesaikan salah satu persyaratan dibawah', // 需满足以下条件之一
      // invite: '1. Undang 10 teman untuk menghasilkan uang bersama', // 1.邀请10个好友一起赚钱
      invite1: 'Undang 3 teman untuk menghasilkan uang bersama', // 1.邀请3个好友一起赚钱
      invite4: 'Undang 10 teman untuk menghasilkan uang bersama', // 1.邀请10个好友一起赚钱
      invite3: 'Undang 30 teman untuk menghasilkan uang bersama', // 1.邀请30个好友一起赚钱
      buySendMoney: '2. Beli diamond keanggotaan seharga Rp2000 ', // 2.花费Rp2000购买砖石会员
      invite2: 'Undang teman', // 邀请好友
      buy: 'Langsung beli', // 立即购买
      myMoney: 'Saldo akun Anda', // 您的账户余额
      under: 'Dibawah', // 低于
      notSendMoney: 'Tidak dapat bertransaksi', // 不能进行交易
      goTopUp: 'Silahkan top-up dulu', // 请前往充值
      know: 'Saya sudah tahu', // 我知道了
      account: 'Penyelesaian', // 结算
    },
    hint: {
      p1:
        '1. Tingkat VIP yang berbeda memiliki jumlah order pembelian yang berbeda',
      p2:
        '2. Semakin tinggi level keanggotaan, semakin tinggi jumlah pesanan yang dapat dibeli',
      p3:
        '3. Untuk pesanan yang terbeli, Anda dapat mengamati status pesanan kapan saja',
    },
  },
  Hint: {
    closed: 'Tutup', // 停服
    notTime: 'Tidak memilih waktu', // 没有选择时间
    notTimeFailed: 'Gagal memilih waktu', // 选择时间错误
    success: 'Berhasil', // 成功
    failed: 'Gagal', // 失败
    phoneNot: 'Phone/Account number cant be empty', // 手机号/账号不能为空
    pwdInconformity: 'Password tidak sama', // 密码不一致
    notMoney: 'Dana tidak cukup', // 货币不足
    notEvent: 'Fitur sementara belum dapat digunakan', // 功能暂未开启
    notInput: 'Input data belum selesai', // 输入内容不完整
    loading: 'Loading', // 加载中
    notMore: 'Tidak ada lagi', // 没有更多了
    notPhone: 'Akun tidak ada', // 账号不存在
    yesPhone: 'Akun sudah ada', // 账号已存在
    levelNot: 'LevelNot', // 等级不够
    serverMaintenance: 'Harap Sabar , Server Game sedang dalam perbaikan.', // 服务器维护中
    invite: 'Undang teman\n untuk menghasikan\n uang bersama', // 分享页面的文字
    goodsNumNo: 'Tidak cukup jumlah', // 物品数量不足
    haveBuy: 'Dibeli', // 订单页面已买文字
    depositBtnText1: 'Received', // 已领取
    depositBtnText2: 'Tersedia', // 可领取
    depositBtnText3: 'Terima', // 领取
    commissionMoney: 'Pengembalian komisi Rp:', // 返佣额度
    bill: 'tagihan', // 玩家查询充值流水记录的记录
    billRecordTitle: 'Catatan tagihan', // 流水记录
    balance: 'Saldo', // 余额
    reference: 'Tampal kode undangan', // 粘贴邀请码
    updateText: 'silahkan update ke versi terbaru',
  }, // 提示
  "payPassword": {
    "title": "Siapkan Kata Sandi Pembayaran",
    "formList": {
      "oldLabel": "Harap masukkan kata sandi pembayaran lama Anda",
      "newLabel": "Harap masukkan kata sandi pembayaran baru"
    }
  }
}
