const lowerLetter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
const uppperLetter = lowerLetter.reduce((prev, current) => {
  prev.push(current.toUpperCase());
  return prev
}, []);
const randomNumber = () => Math.round(Math.random() * 10);
const randomLower = () => {
  const index = Math.round(Math.random() * lowerLetter.length - 1);
  return lowerLetter[index];
}
const randomUpper = () => {
  const index = Math.round(Math.random() * uppperLetter.length - 1);
  return uppperLetter[index];
}
const randomUsernameFun = (type) => {
  switch (type) {
    case "number":
      return randomNumber();
    case "lower":
      return randomLower();
    case "upper":
      return randomUpper();
    default:
      break;
  }
}
export const randomUsername = ({ includes = ["number"], len = 5 }) => {
  let res = "";
  for (let i = 0; i < len; i++) {
    const typeIndex = Math.floor(Math.random() * (includes.length - 1));
    const type = includes[typeIndex];
    res += randomUsernameFun(type);
  }
  return res;
}